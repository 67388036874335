import { Component } from '@angular/core';

@Component({
  selector: 'app-aviso-legal-page',
  templateUrl: './aviso-legal-page.component.html',
  styleUrls: ['./aviso-legal-page.component.scss']
})
export class AvisoLegalPageComponent {

}
