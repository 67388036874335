import { NgModule,Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { ArticuloModel } from 'src/app/models/entity/articulos.model';
import { GridCardsTipoUnoModel } from 'src/app/models/entity/grid_tipo_uno.model';
import { ProductosTipoUnoModel } from 'src/app/models/entity/productos_tipo_uno_model';
import { IFamilia } from 'src/app/models/interface/familias.interface';
import { ISubfamilias } from 'src/app/models/interface/subfamilia.interface';
import { LoadService } from 'src/app/services/helpers/load.service';
import { ArticuloServices } from 'src/app/services/https/articulos.services';
import { FamiliaServices } from 'src/app/services/https/familia.services';
import { SubfamiliaServices } from 'src/app/services/https/subfamilia.services';
import { globalState } from 'src/global';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-catalogo-page',
  templateUrl: './catalogo-page.component.html',
  styleUrls: ['./catalogo-page.component.scss'],
  providers: [FamiliaServices, SubfamiliaServices, ArticuloServices]
})

export class CatalogoPageComponent {

  data: GridCardsTipoUnoModel[] = [];
  urls: string[];
  producto: ProductosTipoUnoModel | null = null;
  pagina_actual: number = 1;
  total_paginas: number = 1;
  registros: number = 20;
  tipo_catalogo: number = 0;
  familia: string = "";
  subfamilia: string = "";
  tiene_paginacion: Boolean = false;
  icono_buscar: IconDefinition = faSearch

  form = new FormGroup({
    valor_busqueda: new FormControl<string>(''),
    valor_interno_busqueda: new FormControl<string>('')
  })

  
  constructor(
    private _familia_services: FamiliaServices,
    private _subfamilia_services: SubfamiliaServices,
    private _articulo_services: ArticuloServices,
    private _loader: LoadService,
    private _toast: ToastrService,
    private _router: Router,
    private titleService: Title,
    private metaService: Meta
  ) {
    // Configurar título de la página
    this.titleService.setTitle('Catálogo de Antigüedades - Diego Reinaldos');

    // Configurar meta tags
    this.metaService.addTags([
      { 
        name: 'description', 
        content: 'Descubra nuestro catálogo de antigüedades restauradas: muebles antiguos, decoración vintage, piezas únicas y más. Calidad y autenticidad en cada artículo.' 
      },
      { 
        name: 'keywords', 
        content: 'antigüedades restauradas, muebles antiguos, decoración vintage, piezas únicas, catálogo de antigüedades, artículos de colección, muebles de época, decoración rústica, objetos antiguos, arte vintage' 
      },
      
    ]);
    const urlSegments = this._router.url.split("/").filter(segment => segment !== "");
    this.urls = urlSegments
    this.tipo_catalogo = urlSegments.length - 1;
    if (this._router.url.indexOf("?") > 0) {
      const decoded_url: string = decodeURI(this._router.url.split('?keyword=')[1])
      const busqueda: string = decoded_url.replaceAll("%20", " ");
      this.form.patchValue({
        valor_busqueda: busqueda,
      });
      this.tipo_catalogo = 3;
    }
  }

  ngOnInit(): void {
    this.data = [];
    const valor_url = this.urls[this.urls.length - 1];
    const es_numero = !isNaN(Number(valor_url));;
    if(es_numero) {
      this.obtener_catalogo_por_id(Number(valor_url))
    } else {
      if (!es_numero) {
        if (this.tipo_catalogo === 0) {
          this.obtener_catalogo_tipo_familia();
        } else if (this.tipo_catalogo === 1) {
          this.familia = this.urls[this.urls.length - 1];
          this.obtener_catalogo_tipo_subfamilia();
        } else if (this.tipo_catalogo === 2) {
          this.familia = this.urls[this.urls.length - 2];
          this.subfamilia = this.urls[this.urls.length - 1];
          this.obtener_catalogo_tipo_productos();
        } else if (this.tipo_catalogo === 3) {
          this.obtener_catalogo_tipo_busqueda();
        }
      }
    }
  }

  async obtener_catalogo_tipo_familia(): Promise<void> {
    try {
      this._loader.notifyLoadChange(true);
      // obtiene familias
      const { familias, pagina_actual, total_paginas } = await this._familia_services.paginacion(this.pagina_actual, this.registros, "");
      this.tiene_paginacion = total_paginas > 1;
      const idioma = globalState.idioma_usuario;
      // convertimos a tipo de dato para usar en el componente
      familias.forEach((item: IFamilia) => {
        let titulo = "";
        let descripcion = "";
        switch(idioma) {
          case "en-EN":
            titulo = item.familia_en !== "" ? item.familia_en : item.familia
            descripcion = item.descripcion_en !== "" ? item.descripcion_en : item.descripcion
            break;
          case "fr-FR":
            titulo = item.familia_fr !== "" ? item.familia_fr : item.familia
            descripcion = item.descripcion_fr !== "" ? item.descripcion_fr : item.descripcion
            break;
          default:
            titulo = item.familia;
            descripcion = item.descripcion
            break;
        }
        const enlace = `/catalogo/${item.familia_enlace}`;
        this.data.push( new GridCardsTipoUnoModel(item.uid,titulo,null,item.file_name, descripcion,enlace,null, null))
      })
      this.pagina_actual = pagina_actual;
      this.total_paginas = total_paginas;
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  async obtener_catalogo_tipo_subfamilia(): Promise<void> {
    try {
      this._loader.notifyLoadChange(true);
      // obtiene familias
      const filtro = `where familia_enlace = '${this.familia}'`
      const { subfamilias, pagina_actual, total_paginas } = await this._subfamilia_services.paginacion(this.pagina_actual, this.registros, filtro);
      this.tiene_paginacion = total_paginas > 1;
      const idioma = globalState.idioma_usuario;

      // convertimos a tipo de dato para usar en el componente
      subfamilias.forEach(async (item: ISubfamilias) => {
        let stock_subfamilia = null;
        if (globalState.identity && globalState.identity.nivel == 99) {
          stock_subfamilia = await this._subfamilia_services.obtiene_stock_subfamilia(item.uid);
        } 
        let titulo = "";
        let descripcion = "";
        switch(idioma) {
          case "en-EN":
            titulo = item.subfamilia_en !== "" ? item.subfamilia_en : item.subfamilia
            descripcion = item.descripcion_en !== "" ? item.descripcion_en : item.descripcion
            break;
          case "fr-FR":
            titulo = item.subfamilia_fr !== "" ? item.subfamilia_fr : item.subfamilia
            descripcion = item.descripcion_fr !== "" ? item.descripcion_fr : item.descripcion
            break;
          default:
            titulo = item.subfamilia;
            descripcion = item.descripcion
            break;
        }
        const enlace = `/catalogo/${this.familia}/${item.subfamilia_enlace}`;
        this.data.push( new GridCardsTipoUnoModel(item.uid,titulo,null,item.file_name, descripcion,enlace,null, stock_subfamilia))
      })
      this.pagina_actual = pagina_actual;
      this.total_paginas = total_paginas;
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  async obtener_catalogo_tipo_productos(): Promise<void> {
    try {
      this._loader.notifyLoadChange(true);
      // obtiene familias
      const filtro = `where familia_enlace = '${this.familia}' AND  subfamilia_enlace = '${this.subfamilia}' AND stock > 0`
      const { articulos, pagina_actual, total_paginas } = await this._articulo_services.paginacion(this.pagina_actual, this.registros, filtro);
      this.tiene_paginacion = total_paginas > 1;
      const idioma = globalState.idioma_usuario;
      // convertimos a tipo de dato para usar en el componente
      articulos.forEach((item: ArticuloModel) => {
        let titulo = "";
        let descripcion = "";
        switch(idioma) {
          case "en-EN":
            titulo = item.articulo_en !== "" ? item.articulo_en : item.articulo
            descripcion = item.descripcion_en !== "" ? item.descripcion_en : item.descripcion
            break;
          case "fr-FR":
            titulo = item.articulo_fr !== "" ? item.articulo_fr : item.articulo
            descripcion = item.descripcion_fr !== "" ? item.descripcion_fr : item.descripcion
            break;
          default:
            titulo = item.articulo;
            descripcion = item.descripcion
            break;
        }
        console.log(item.referencia)
        const enlace = `/catalogo/${this.familia}/${item.subfamilia_enlace}/${item.uid}`;
        this.data.push( new GridCardsTipoUnoModel(item.uid,titulo,item.referencia,item.file_name, descripcion,enlace,null, item.stock))
      })
      this.pagina_actual = pagina_actual;120
      this.total_paginas = total_paginas;
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  async obtener_catalogo_por_id(uid: number): Promise<void> {
    try {
      this._loader.notifyLoadChange(true);
      // obtiene familias
      const filtro = `where uid = ${uid} AND stock > 0`
      const { articulos, pagina_actual, total_paginas } = await this._articulo_services.paginacion(this.pagina_actual, this.registros, filtro);
      this.tiene_paginacion = total_paginas > 1;
      const idioma = globalState.idioma_usuario;
      // convertimos a tipo de dato para usar en el componente
      articulos.forEach((item: ArticuloModel) => {
        let titulo = "";
        let descripcion = "";
        switch(idioma) {
          case "en-EN":
            titulo = item.articulo_en !== "" ? item.articulo_en : item.articulo
            descripcion = item.descripcion_en !== "" ? item.descripcion_en : item.descripcion
            break;
          case "fr-FR":
            titulo = item.articulo_fr !== "" ? item.articulo_fr : item.articulo
            descripcion = item.descripcion_fr !== "" ? item.descripcion_fr : item.descripcion
            break;
          default:
            titulo = item.articulo;
            descripcion = item.descripcion
            break;
        }
        const enlace = `/catalogo/${item.familia_enlace}/${item.subfamilia_enlace}/${item.uid}`;
        this.data.push( new GridCardsTipoUnoModel(item.uid,titulo,item.referencia,item.file_name, descripcion,enlace,null, null))
      })
      this.pagina_actual = pagina_actual;120
      this.total_paginas = total_paginas;
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  async obtener_catalogo_tipo_busqueda(): Promise<void> {
    try {
      this._loader.notifyLoadChange(true);
      // obtiene familias
      const valores = this.form.value.valor_interno_busqueda?.split(" ");
      let filtro = "where "
      valores?.forEach((item: string, index: number) => {
        filtro += `((INSTR(keywords, '${item}') > 0 AND stock > 0) OR (INSTR(referencia, '${item}') > 0 AND stock > 0))`;
        filtro += ``
        if (index < valores.length - 1) {
          filtro += ` OR `
        }
      })
      const { articulos, pagina_actual, total_paginas } = await this._articulo_services.paginacion(this.pagina_actual, this.registros, filtro);
      this.tiene_paginacion = total_paginas > 1;
      const idioma = globalState.idioma_usuario;
      // convertimos a tipo de dato para usar en el componente
      articulos.forEach((item: ArticuloModel) => {
        let titulo = "";
        let descripcion = "";
        switch(idioma) {
          case "en-EN":
            titulo = item.articulo_en !== "" ? item.articulo_en : item.articulo
            descripcion = item.descripcion_en !== "" ? item.descripcion_en : item.descripcion
            break;
          case "fr-FR":
            titulo = item.articulo_fr !== "" ? item.articulo_fr : item.articulo
            descripcion = item.descripcion_fr !== "" ? item.descripcion_fr : item.descripcion
            break;
          default:
            titulo = item.articulo;
            descripcion = item.descripcion
            break;
        }
        const enlace = `/catalogo/${item.familia_enlace}/${item.subfamilia_enlace}/${item.uid}`;
        this.data.push( new GridCardsTipoUnoModel(item.uid,titulo,item.referencia,item.file_name, descripcion,enlace,null, item.stock))
      })
      this.pagina_actual = pagina_actual;120
      this.total_paginas = total_paginas;
    } catch(e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  recibe_pagina(event: any): void {
    this.pagina_actual = event;
    window.scrollTo({ top: 250, behavior: 'smooth' });
    this.ngOnInit();
  }

  buscar_articulos() {
    if (this.form.controls.valor_busqueda.value === "") {
      this._toast.warning("Campo de búsqueda vacío")
      return;
    } else {
      this.tipo_catalogo = 3;
      this.data = [];
      const value = encodeURI(this.form.value.valor_busqueda!!);
      this.form.patchValue({
        valor_interno_busqueda: value
      })
      this.obtener_catalogo_tipo_busqueda();
    }
  }
}