<ngb-carousel
  #carousel
  [interval]="3000"
  [pauseOnHover]="pauseOnHover"
  [pauseOnFocus]="pauseOnFocus"
  (slide)="onSlide($event)"
>
  <ng-template ngbSlide *ngFor="let slider of sliders; index as i">
    <div class="carousel-caption">
      <h3 *ngIf="slider.link === ''">{{ slider.titulo }}</h3>
      <button
        type="button"
        *ngIf="slider.link !== ''"
        class="btn btn-primary"
        (click)="enviarSlideId(slider.id_slider)"
      >
        {{ slider.titulo }}
      </button>
    </div>
    <div class="picsum-img-wrapper">
      <div
        class="slider-container"
        [ngStyle]="{
          'background-image': 'url(' + slider.imagen + ')',
          'background-size': 'cover',
          'background-position': 'center',
          'background-repeat': 'no-repeat',
          width: '100%',
        }"
      ></div>
    </div>
  </ng-template>
</ngb-carousel>
