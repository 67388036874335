import { Component, Input, ViewChild } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-producto-content-tipo-uno-slider',
  templateUrl: './producto-content-tipo-uno-slider.component.html',
  styleUrls: ['./producto-content-tipo-uno-slider.component.scss']
})
export class ProductoContentTipoUnoSliderComponent {
  @ViewChild('carousel', { static: true }) carousel!: NgbCarousel;
	pausa_al_pasar = true;
	pausa_al_centrar = true;
  @Input() imgs!: string[] 
}
