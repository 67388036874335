import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { globalState } from 'src/global';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent {
  usuario: UsuarioModel | undefined = globalState.identity
  intranet_url: string = 'intranet'
  intranet: boolean = false;

  constructor(
    private _router: Router
  ){
    this.intranet = this._router.url.includes(this.intranet_url);
  }
}
