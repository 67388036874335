import { globalState } from "src/global";
import { ISlider } from "../interface/slider.interface";

export class ModelSlider implements ISlider {
  id_slider: number;
  titulo: string;
  activo: boolean;
  orden: number;
  texto: string;
  link: string;
  imagen: string;
  constructor(
    id_slider: number,
    titulo: string,
    activo: boolean,
    orden: number,
    texto: string,
    link: string,
    imagen: string,
  ) {
    this.id_slider = id_slider;
    this.titulo = titulo;
    this.activo = activo;
    this.orden = orden;
    this.texto = texto;
    this.link = link;
    this.imagen = `${globalState.urlImg}slider/${imagen}`;
  }
}
