<app-page-container-publico [title] = "'Catálogo'">
  <app-breadcrumb-container id="breadcrumb"/>

  <div *ngIf="data.length > 0" class="container">
    <form class="d-flex justify-content-end mt-5" (ngSubmit)="buscar_articulos()">
      <div class="busqueda position-relative" style="max-width: 250px;">
        <input
          [formControl]="form.controls.valor_busqueda"
          type="text"
          class="form-control mr-sm-2 "
          name="busqueda"
          type="text"
          placeholder=" Buscar..."
        />
        <div class="position-absolute top-0 end-0 h-100">
          <button class="btn-buscar" type="submit">
            <fa-icon [icon]="icono_buscar" />
          </button>
        </div>
      </div>
    </form>
  </div>

  <!-- Tipo familia-->
  <app-grid-cards-tipo-uno *ngIf="tipo_catalogo === 0"
    [data]="data"
  />
  
  <!-- Tipo subfamilia-->
  <app-grid-cards-tipo-uno *ngIf="tipo_catalogo === 1"
    [data]="data"
  />

  <!-- Tipo productos -->
  <app-grid-cards-tipo-uno *ngIf="tipo_catalogo === 2"
    [data]="data"
  />

  <!-- Tipo busqueda -->
  <app-grid-cards-tipo-uno *ngIf="tipo_catalogo === 3"
    [data]="data"
  />

  <!-- Barra de Navegacion -->
  <div class="d-flex justify-content-center pb-5">
    <app-paginacion-tipo-dos *ngIf="tiene_paginacion && data.length > 0"
      class=""
      [pagina_actual]="pagina_actual"
      [total_paginas]="total_paginas"
      (cambia_pagina)="recibe_pagina($event)"
    />
  </div>
  

</app-page-container-publico>