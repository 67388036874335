import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicioPageComponent } from './pages/publico/inicio-page/inicio-page.component';
import { PageContainerPublicoComponent } from './components/publico/page-container-publico/page-container-publico.component';
import { HeaderContainerComponent } from './components/publico/header/header-container/header-container.component';
import { HeaderTopComponent } from './components/common/header-top/header-top.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderNavPublicoComponent } from './components/publico/header/header-nav-publico/header-nav-publico.component';
import { SliderComponent } from './components/common/slider/slider.component';
import { InputComponent } from './components/common/forms/input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomHttpInterceptor } from './services/https/http-interceptor';
import { LoadingComponentComponent } from './components/common/loading-component/loading-component.component';
import { ToastrModule } from 'ngx-toastr';
import { BoxMensajeComponent } from './components/box-mensaje/box-mensaje.component';
import { UsuarioOptionsComponent } from './components/privado/header/usuario-options/usuario-options.component';
import { AdminOptionsComponent } from './components/privado/header/admin-options/admin-options.component';
import { SuperOptionsComponent } from './components/privado/header/super-options/super-options.component';
import { GeneralOptionsComponent } from './components/privado/header/general-options/general-options.component';
import { TableDefaultComponent } from './components/common/table-default/table-default.component';
import { CommonModule } from '@angular/common';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TinyEditorComponent } from './components/common/forms/tiny-editor/tiny-editor.component';
import { ColorPickerComponent } from './components/common/forms/color-picker/color-picker.component';
import { ReverseSwitchComponent } from './components/common/forms/reverse-switch/reverse-switch.component';
import { LinealComponentSelectorComponent } from './components/common/lineal-component-selector/lineal-component-selector.component';
import { DynamicComponentDirective } from './directives/dynamic-component';
import { SelectInputComponent } from './components/common/forms/select-input/select-input.component';
import { TarjetaPrintComponent } from './components/privado/tarjeta-print/tarjeta-print.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ItemTableNumberNameComponent } from './components/common/table-default/components/item-table-validado/item-table-number-name.component';
import { ItemTableBooleansComponent } from './components/common/table-default/components/item-table-booleans/item-table-booleans.component';
import { TextEditorComponent } from './components/common/forms/text-editor/text-editor.component';
import { FacturaPrintComponent } from './components/privado/factura-print/factura-print.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { NgChartsModule } from 'ng2-charts';
import { ChartComponent } from './components/common/forms/chart/chart.component';
import { ItemTableStringComponent } from './components/common/table-default/components/item-table-string/item-table-string.component';
import { BorradoModalComponent } from './components/common/borrado-modal/borrado-modal.component';
import { CatalogoPageComponent } from './pages/publico/catalogo-page/catalogo-page.component';
import { BreadcrumbContainerComponent } from './components/common/breadcrumb-container/breadcrumb-container.component';
import { GridCardsTipoUnoComponent } from './components/common/grid-cards-tipo-uno/grid-cards-tipo-uno.component';
import { ProductoPageComponent } from './pages/publico/producto-page/producto-page.component';
import { ProductoContentTipoUnoComponent } from './components/common/producto-content-tipo-uno/producto-content-tipo-uno.component';
import { ProductoContentTipoUnoSliderComponent } from './components/common/producto-content-tipo-uno/components/producto-content-tipo-uno-slider/producto-content-tipo-uno-slider.component';
import { QuienesSomosComponent } from './pages/publico/quienes-somos/quienes-somos.component';
import { ContactoPageComponent } from './pages/publico/contacto-page/contacto-page.component';
import { CardIconoTextoEnlaceTipoUnoComponent } from './components/common/card-icono-texto-enlace-tipo-uno/card-icono-texto-enlace-tipo-uno.component';
import { PaginacionTipoDosComponent } from './components/common/paginacion-tipo-dos/paginacion-tipo-dos.component';
import { LoginPageComponent } from './pages/privado/login-page/login-page.component';
import { IntranetComponent } from './pages/privado/intranet/intranet.component';
import { AdminHeaderComponent } from './components/privado/admin-header/admin-header.component';
import { IntranetContainerComponent } from './components/common/intranet-container/intranet-container.component';
import { IntranetSidebarComponent } from './components/common/intranet-container/components/intranet-sidebar/intranet-sidebar.component';
import { IntranetArticulosPageComponent } from './pages/privado/intranet-articulos-page/intranet-articulos-page.component';
import { IntranetArticulosPageFormComponent } from './pages/privado/intranet-articulos-page/components/intranet-articulos-page-form/intranet-articulos-page-form.component';
import { IntranetFamiliasPageComponent } from './pages/privado/intranet-familias-page/intranet-familias-page.component';
import { IntranetFamiliasFormComponent } from './pages/privado/intranet-familias-page/components/intranet-familias-form/intranet-familias-form.component';
import { IntranetSubfamiliasComponent } from './pages/privado/intranet-subfamilias/intranet-subfamilias.component';
import { IntranetSubfamiliasFormComponent } from './pages/privado/intranet-subfamilias/components/intranet-subfamilias-form/intranet-subfamilias-form.component';
import { IntranetNovedadesComponent } from './pages/privado/intranet-novedades/intranet-novedades.component';
import { IntranetNovedadesFormComponent } from './pages/privado/intranet-novedades/intranet-novedades-form/intranet-novedades-form.component';
import { IntranetSliderComponent } from './pages/privado/intranet-slider/intranet-slider.component';
import { IntranetSliderFormComponent } from './pages/privado/intranet-slider/components/intranet-slider-form/intranet-slider-form.component';
import { IntranetUsuariosComponent } from './pages/privado/intranet-usuarios/intranet-usuarios.component';
import { IntranetUsuariosFormComponent } from './pages/privado/intranet-usuarios/components/intranet-usuarios-form/intranet-usuarios-form.component';
import { IntranetZonasComponent } from './pages/privado/intranet-zonas/intranet-zonas.component';
import { IntranetZonasFormComponent } from './pages/privado/intranet-zonas/components/intranet-zonas-form/intranet-zonas-form.component';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { IntranetConfiguracionPageComponent } from './pages/privado/intranet-configuracion-page/intranet-configuracion-page.component';
import { AvisoLegalPageComponent } from './pages/publico/aviso-legal-page/aviso-legal-page.component';
import { PoliticaPrivacidadPageComponent } from './pages/publico/politica-privacidad-page/politica-privacidad-page.component';
import { PoliticaCookiesPageComponent } from './pages/publico/politica-cookies-page/politica-cookies-page.component';

@NgModule({
  declarations: [
    AppComponent,
    InicioPageComponent,
    PageContainerPublicoComponent,
    HeaderContainerComponent,
    HeaderTopComponent,
    HeaderNavPublicoComponent,
    SliderComponent,
    InputComponent,
    LoadingComponentComponent,
    BoxMensajeComponent,
    UsuarioOptionsComponent,
    AdminOptionsComponent,
    SuperOptionsComponent,
    GeneralOptionsComponent,
    TableDefaultComponent,
    TinyEditorComponent,
    TextEditorComponent,
    ColorPickerComponent,
    ReverseSwitchComponent,
    LinealComponentSelectorComponent,
    DynamicComponentDirective,
    SelectInputComponent,
    ItemTableNumberNameComponent,
    ItemTableBooleansComponent,
    TarjetaPrintComponent,
    FacturaPrintComponent,
    PaginatorComponent,
    ChartComponent,
    ItemTableStringComponent,
    BorradoModalComponent,
    CatalogoPageComponent,
    BreadcrumbContainerComponent,
    GridCardsTipoUnoComponent,
    ProductoPageComponent,
    ProductoContentTipoUnoComponent,
    ProductoContentTipoUnoSliderComponent,
    QuienesSomosComponent,
    ContactoPageComponent,
    CardIconoTextoEnlaceTipoUnoComponent,
    PaginacionTipoDosComponent,
    LoginPageComponent,
    IntranetComponent,
    AdminHeaderComponent,
    IntranetContainerComponent,
    IntranetSidebarComponent,
    IntranetArticulosPageComponent,
    IntranetArticulosPageFormComponent,
    IntranetFamiliasPageComponent,
    IntranetFamiliasFormComponent,
    IntranetSubfamiliasComponent,
    IntranetSubfamiliasFormComponent,
    IntranetNovedadesComponent,
    IntranetNovedadesFormComponent,
    IntranetSliderComponent,
    IntranetSliderFormComponent,
    IntranetUsuariosComponent,
    IntranetUsuariosFormComponent,
    IntranetZonasComponent,
    IntranetZonasFormComponent,
    CookieBannerComponent,
    IntranetConfiguracionPageComponent,
    AvisoLegalPageComponent,
    PoliticaPrivacidadPageComponent,
    PoliticaCookiesPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    ReactiveFormsModule,
    CommonModule,
    EditorModule,
    FontAwesomeModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    HttpClientModule,
    QRCodeModule,
    NgChartsModule
  ],
  providers: [
    {
       provide: HTTP_INTERCEPTORS,
       useClass: CustomHttpInterceptor,
       multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
