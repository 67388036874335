import { Component } from '@angular/core';

@Component({
  selector: 'app-politica-privacidad-page',
  templateUrl: './politica-privacidad-page.component.html',
  styleUrls: ['./politica-privacidad-page.component.scss']
})
export class PoliticaPrivacidadPageComponent {

}
