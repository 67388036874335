import { globalState } from "src/global";
import { IArticuloImagenInterface } from "../interface/articulo_imagen.interface";

export class ArticuloImagenModel implements IArticuloImagenInterface {
  uid: number;
  articulo_uid: number;
  idioma: string;
  titulo: string;
  descripcion: string;
  url_doc: string;
  file_type: string;
  file_name: string;
  file_size: number;
  fecha_alta: string;
  fecha_modificacion: string | null;
  usuario_alta: number;
  usuario_modificacion: number;
  usuario_consultas: number;
  consultas: number;
  modificaciones: number;

  constructor(
   item: IArticuloImagenInterface
  ) {
    this.uid = item.uid;
    this.articulo_uid = item.articulo_uid;
    this.idioma = item.idioma;
    this.titulo = item.titulo;
    this.descripcion = item.descripcion;
    this.url_doc = item.url_doc;
    this.file_type = item.file_type;
    this.file_name = `${globalState.urlImg}articulos/${item.file_name}`;
    this.file_size = item.file_size;
    this.fecha_alta = item.fecha_alta;
    this.fecha_modificacion = item.fecha_modificacion;
    this.usuario_alta = item.usuario_alta;
    this.usuario_modificacion = item.usuario_modificacion;
    this.usuario_consultas = item.usuario_consultas;
    this.consultas = item.consultas;
    this.modificaciones = item.modificaciones;
  }
}