import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BorradoModalComponent } from 'src/app/components/common/borrado-modal/borrado-modal.component';
import { NovedadModel } from 'src/app/models/entity/novedad.model';
import { IActualizaNovedad, IInsertaNovedad } from 'src/app/models/interface/novedad.interface';
import { LoadService } from 'src/app/services/helpers/load.service';
import { NovedadServices } from 'src/app/services/https/novedad.services';

@Component({
  selector: 'app-intranet-novedades',
  templateUrl: './intranet-novedades.component.html',
  styleUrls: ['./intranet-novedades.component.scss'],
  providers: [NovedadServices]
})
export class IntranetNovedadesComponent {
  es_listado: Boolean;
  data: NovedadModel[] = [];
  novedad: NovedadModel | null = null
  id: number = 0;
  registros: number = 100;
  pagina_actual: number = 1;
  total_paginas: number = 0;
  filtro: string = '';
  cargando: boolean = true;

  constructor(
    private _router: Router,
    private _loader: LoadService,
    private _toast: ToastrService,
    private _modal_service: NgbModal,
    private _novedades_service: NovedadServices,
    private titleService: Title,
    private metaService: Meta
  ) {
    const { url } = this._router
    const segments = url.split('/');
    this.es_listado = segments.length === 3;
    if (!this.es_listado) {
      if (segments[segments.length - 1] !== "nuevo") {
        this.id = Number(segments[segments.length - 1]);
      } else {
        this.cargando = false;
      }
    }
  }

  async ngOnInit() {
    this.titleService.setTitle('Intranet - Antigüedades Diego Reinaldos');
    this.metaService.addTags([
    ]);
    if (this.es_listado) {
      try {
        this._loader.notifyLoadChange(true);
        this.data = await this.obtener_novedades();
        console.log(this.data);
      } catch (e: any) {
        this._toast.error(e);
      } finally {
        this._loader.notifyLoadChange(false);
      }
    } else {
      if (this.id !== 0) {
        try {
          this._loader.notifyLoadChange(true);
          this.novedad = await this._novedades_service.ficha(this.id)
        } catch (e: any) {
          this._toast.error(e);
        } finally {
          this._loader.notifyLoadChange(false);
          this.cargando = false
        }
      }
    }
  }

  async obtener_novedades(): Promise<NovedadModel[]> {
    try {
      return await this._novedades_service.lista_intranet('')
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  abrir_modal_borrado(id: number) {
    const modalRef = this._modal_service.open(BorradoModalComponent, { centered: true })
    modalRef.componentInstance.tipo_objeto = "novedades"
    modalRef.componentInstance.id = id
    modalRef.componentInstance.tabla = "novedades"
    modalRef.componentInstance.borrado_exitoso.subscribe(async (borrado_exitoso: boolean) => {
      if (borrado_exitoso) {
        try {
          this._loader.notifyLoadChange(true);
          await this._novedades_service.elimina(id);
        } catch (e: any) {
          this._toast.error(e);
          this._loader.notifyLoadChange(false);
        } finally {
          this.ngOnInit();
        }
      }
    });
  }

  async recibe_nueva_novedad(evento: IInsertaNovedad) {
    try {
      this._loader.notifyLoadChange(true);
      const novedad = await this._novedades_service.inserta(evento);
      this._toast.success('Novedad creada con éxito');
      this._router.navigate(['/intranet/novedades'])
    } catch (e: any) {
      console.log(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  async recibe_actualiza_novedad(evento: IActualizaNovedad) {
    try {
      this._loader.notifyLoadChange(true);
      const novedad = await this._novedades_service.actualiza(this.id, evento);
      this._toast.success('Novedad actualizada con éxito');
      this._router.navigate(['/intranet/novedades'])
    } catch (e: any) {
      console.log(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }
}
