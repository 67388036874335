import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { NovedadModel } from 'src/app/models/entity/novedad.model';
import { IActualizaNovedad, IInsertaNovedad } from 'src/app/models/interface/novedad.interface';
import { generateRandomName } from 'src/app/services/helpers/utils.helper';

@Component({
  selector: 'app-intranet-novedades-form',
  templateUrl: './intranet-novedades-form.component.html',
  styleUrls: ['./intranet-novedades-form.component.scss']
})
export class IntranetNovedadesFormComponent {
  @Input() es_nuevo!: boolean;
  @Input() novedad!: NovedadModel | null;
  icono_cerrar: IconDefinition = faClose;
  imagen_a_mostrar: string = '';
  imagen_para_form_data: File | undefined = undefined;
  @Output() envia_nueva_novedad: EventEmitter<IInsertaNovedad> =new EventEmitter<IInsertaNovedad>()
  @Output() envia_actualiza_novedad: EventEmitter<IActualizaNovedad> =new EventEmitter<IActualizaNovedad>()
  constructor(
    private _toast: ToastrService
  ){}

  formGroup = new FormGroup({
    uid: new FormControl(0),
    idioma: new FormControl<string>('', [Validators.required]),
    novedad: new FormControl<string>('', [Validators.required]),
    descripcion: new FormControl<string>('', [Validators.required]),
    keywords: new FormControl<string>('', [Validators.required]),
    file_name: new FormControl<string>('', [Validators.required]),
  });

  ngOnInit() {
    if (!this.es_nuevo && this.novedad !== null)
      this.rellena_formulario(this.novedad)
  }

  rellena_formulario(novedad: NovedadModel) {
    this.formGroup.patchValue({
      idioma: novedad.idioma,
      novedad: novedad.novedad,
      descripcion: novedad.descripcion,
      keywords: novedad.keywords,
      file_name: novedad.file_name
    });
    this.imagen_a_mostrar = novedad.file_name
  }

  formatear_archivo(evento: any): void {
    const upload_event = evento.target;
    if (upload_event.files.length > 0) {
      // Solo gestionamos el primer archivo (puedes modificarlo para gestionar varios)
      const archivo: File = upload_event.files[0];
      const extension = archivo.name.split('.').slice(-1)[0].toLowerCase();

      // Genera un nombre aleatorio para el archivo
      const filename = `${generateRandomName()}.${extension}`;
      const archivo_modificado = new File([archivo], filename, { type: archivo.type })
      this.formGroup.patchValue({ file_name: filename });
      // Asigna el archivo formateado a la propiedad imagen_articulo

      this.imagen_para_form_data = archivo_modificado;

      // Verifica si es una imagen o un PDF para mostrar la vista previa
      if (['png', 'jpg', 'jpeg'].includes(extension)) {
        // Si es una imagen, genera una URL temporal para mostrar la vista previa
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imagen_a_mostrar = e.target.result

        };
        reader.readAsDataURL(archivo_modificado); // Lee el archivo como Data URL
      } else if (extension === 'pdf') {
        // Si es un PDF, también generamos una URL temporal
        const pdfUrl = URL.createObjectURL(archivo_modificado);
        this.formGroup.patchValue({
          file_name: pdfUrl // Establece la URL del PDF en el formulario
        });
      }
    }
  }

  borra_imagen() {
    this.formGroup.patchValue({
      file_name: ''
    });
    this.imagen_a_mostrar = '';

  }

  on_submit() {
    const validado = this.comprueba_formulario();
    if (validado) {
      if (this.es_nuevo) {
        const inserta = this.formato_inserta()
        this.envia_nueva_novedad.emit(inserta);
      } else {
        const actualiza = this.formato_inserta()
        this.envia_actualiza_novedad.emit(actualiza);
      }
    }
  }

  comprueba_formulario() {
    const { novedad, idioma, descripcion, keywords, file_name } = this.formGroup.value
    if (novedad === null && novedad === undefined && novedad === '') {
      this._toast.error("Novedad es obligatorio")
      return false;
    }
    if (idioma === null && idioma === undefined && idioma === '') {
      this._toast.error("Idioma es obligatorio")
      return false;
    }
    if (descripcion === null && descripcion === undefined && descripcion === '') {
      this._toast.error("Descripción es obligatorio")
      return false;
    }
    if (file_name === null && file_name === undefined && file_name === '') {
      this._toast.error("Imagen obligatoria")
      return false;
    }
    return true;
  }

  formato_inserta(): IInsertaNovedad {
    return {
      idioma: this.formGroup.value.idioma!!,
      descripcion: this.formGroup.value.descripcion!!,
      novedad: this.formGroup.value.novedad!!,
      keywords: this.formGroup.value.keywords!!,
      file_name: this.formGroup.value.file_name!!,
      imagen: this.imagen_para_form_data!!
    }
  }

  formato_actualiza(): IActualizaNovedad {
    return {
      idioma: this.formGroup.value.idioma!!,
      descripcion: this.formGroup.value.descripcion!!,
      novedad: this.formGroup.value.novedad!!,
      keywords: this.formGroup.value.keywords!!,
      file_name: this.formGroup.value.file_name!!,
      imagen: this.imagen_para_form_data
    }
  }
}
