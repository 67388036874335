import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { TipoClienteModel } from 'src/app/models/entity/tipoCliente.model';
import { ConfigGeneralModel } from 'src/app/models/entity/configuracion.model';
import { createConfigGenereal } from '../helpers/model.helper';

@Injectable()
export class ConfiguracionService {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router) {}

  // Realiza el login del usuario
  ficha(id: number): Observable<any> {
    const params = new HttpParams().set('id', id + '');

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(
      this.url + 'configuracion/ficha',
      params,
      httpOptions
    );
  }

  async ficha_nueva(id: number): Promise<ConfigGeneralModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}configuracion/${id}`, httpOptions));
      return createConfigGenereal(data)
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al iniciar sesión';
      return Promise.reject(errorMessage);
    }
  }

  // Realiza el login del usuario
  lista(): Observable<any> {
    const params = new HttpParams();
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(
      this.url + 'configuracion/lista',
      params,
      httpOptions
    );
  }


  // Realiza el login del usuario
  listaZonas(): Observable<any> {
    const params = new HttpParams();

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(
      this.url + 'configuracionzona/lista',
      params,
      httpOptions
    );
  }

  async actualiza(
    horario1: string,
    horario2: string,
    telefono: string, 
    telefono_whatsapp: string,
    horario1_en: string,
    horario1_fr: string,
    horario2_en: string,
    horario2_fr: string
  ): Promise<boolean> {

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const formData = new FormData();
    formData.append('horario1', horario1)
    formData.append('horario1_en', horario1_en)
    formData.append('horario1_fr', horario1_fr)
    formData.append('horario2', horario2);
    formData.append('horario2_en', horario2_en);
    formData.append('horario2_fr', horario2_fr);
    formData.append('telefono', telefono);
    formData.append('telefono_whatsapp', telefono_whatsapp);

    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}configuracion/actualiza`, formData, httpOptions));
      return true
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al insertar una fmailia';
      return Promise.reject(errorMessage);
    }
  }
}
