import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NovedadModel } from 'src/app/models/entity/novedad.model';
import { ModelSlider } from 'src/app/models/entity/slider.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { NovedadServices } from 'src/app/services/https/novedad.services';
import { SliderServices } from 'src/app/services/https/slider.services';
import { globalState } from 'src/global';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-inicio-page',
  templateUrl: './inicio-page.component.html',
  styleUrls: ['./inicio-page.component.scss'],
  providers: [SliderServices, NovedadServices]
})
export class InicioPageComponent {
  sliders: ModelSlider[] = [];
  novedades: NovedadModel[] = [];
  constructor(
    private _slider_services: SliderServices,
    private _novedad_services: NovedadServices,
    private _loader: LoadService,
    private _toast: ToastrService,
    private _titleService: Title,
    private _metaService: Meta
  ) {

  }

  async ngOnInit(): Promise<void> {
    try {
      this._titleService.setTitle("Antigüedades Reinaldos - Venta de Antigüedades Restauradas");
      this._metaService.addTags([
        { name: 'description', content: 'Explora Antigüedades Reinaldos: piezas únicas restauradas, muebles antiguos de madera maciza, vigas rústicas, yunques de fragua, decoración vintage y más. Tienda de antigüedades y restauración de calidad.' },
        { name: 'keywords', content: 'antigüedades restauradas, muebles antiguos, decoración vintage, tienda de antigüedades, piezas únicas, mesas de madera maciza, vigas rústicas, yunques antiguos, puertas rústicas, artículos de colección, decoración para jardines, maderas para pérgolas, muebles de baño rústicos, rejas de forja antiguas, decoración para techos rústicos' },
      ]);
      this._loader.notifyLoadChange(true);
      this.sliders = await this._slider_services.lista();
      const filtro = this.crea_filtro();
      this.novedades = await this._novedad_services.lista(filtro);
    } catch (e: any) {
      this._toast.error(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }


  crea_filtro(): string {
    const idioma = globalState.idioma_usuario.split('-')[0];
    return `where idioma = '${idioma}'`
  }
}
