import { Injectable } from '@angular/core';
import { Router, Params } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { createZona } from '../helpers/model.helper';
import { IInsertaZona } from 'src/app/models/interface/zona.interface';

@Injectable()
export class ZonasService {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router,) {
  }

  async lista_completa(): Promise<{ zonas: ZonasModel[] }> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}zonas`, httpOptions));
      const zonas: ZonasModel[] = [];
      data.forEach((item: any) => {
        zonas.push(createZona(item))
      })
      return { zonas }
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  async ficha(id: number): Promise<ZonasModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}zonas/${id}`, httpOptions));
      return createZona(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener las zonas';
      return Promise.reject(errorMessage);
    }
  }

  async crea(datos: IInsertaZona): Promise<ZonasModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}zonas`, datos, httpOptions))
      return createZona(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear la zona';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza(id: number, datos: IInsertaZona): Promise<ZonasModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.put<any>(`${this.url}zonas/${id}`, datos, httpOptions))
      return createZona(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear la zona';
      return Promise.reject(errorMessage);
    }
  }

  async elimina(id: number): Promise<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      await firstValueFrom(this._http.delete<any>(`${this.url}zonas/${id}`, httpOptions))
      return true;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear la zona';
      return Promise.reject(errorMessage);
    }
  }
}
