import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-container',
  templateUrl: './header-container.component.html',
  styleUrls: ['./header-container.component.scss']
})
export class HeaderContainerComponent {
  @Input() horario1!: string;
  @Input() horario2!: string;
  @Input() telefono!: string;
  @Input() telefono_whatsapp!: string;
}
