<app-page-container-publico [title]="'Catálogo'">
  <app-breadcrumb-container id="breadcrumb" />
  <div class="about-area ptb-100 mt-5">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 mx-auto">
          <div class="about-content">
            <h2 class="mb-3">Objeto y aceptación</h2>
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <p class="parrafo mt-3">El presente aviso legal regula el uso del sitio web https://diegoreinaldos.com/, que pone a su disposición la entidad responsable del sitio web cuyos datos identificativos son:</p>
            <ul>
              <li>
                <strong>NOMBRE: </strong> NURIA REINALDOS Y OTRA, C.B.
              </li>
              <li>
                <strong>CIF: </strong> E30454441
              </li>
              <li>
                <strong>DOMICILIO: </strong> CAMINO CENTRAL, PANEL 55 - Nº 128 -
                Lorca
              </li>
              <li>
                <strong>TELÉFONO: </strong> 968 40 15 33
              </li>
              <li>
                <strong>EMAIL: </strong> info@diegoreinaldos.com
              </li>
            </ul>
            <p class="parrafo">A la que en adelante podremos referirnos como
              “empresa”.</p>
            <p class="parrafo">La navegación por el sitio web de la empresa
              atribuye la condición de usuario de este, e implica la aceptación
              plena y sin reservas de todas y cada una de las disposiciones
              incluidas en este aviso legal, que pueden sufrir
              modificaciones.</p>
            <p class="parrafo">El usuario se obliga a hacer un uso correcto del
              sitio web de conformidad con las leyes, la buena fe, el orden
              público, los usos del tráfico y el presente aviso legal. El
              usuario responderá frente a la empresa o frente a terceros, de
              cualesquiera daños y perjuicios que pudieran causarse como
              consecuencia del incumplimiento de dicha obligación.</p>
          </div>
          <div class="about-content mt-3">
            <h2 class="m-3">Condiciones de acceso y utilización</h2>
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <p class="parrafo">El sitio web y sus servicios son de acceso libre,
              no obstante, la empresa condiciona la utilización de algunos de
              los servicios ofrecidos en su web a la previa cumplimentación del
              correspondiente formulario. El usuario garantiza la autenticidad y
              actualidad de todos aquellos datos que comunique a la empresa y
              será el único responsable de manifestaciones inexactas o falsas
              que realice. El usuario se compromete expresamente a hacer un uso
              adecuado de los contenidos y servicios de la empresa y a no
              emplearlos para, entre otros:</p>
            <ul>
              <li>
                <p class="parrafo">Difundir contenidos delictivos, violentos,
                  pornográficos, racistas, xenófobos, ofensivos, de apología del
                  terrorismo o, en general contrarios a la ley o el orden
                  público.</p>
              </li>
              <li>
                <p class="parrafo">Introducir en la red virus informáticos o
                  realizar actuaciones susceptibles de alterar, estropear,
                  interrumpir o generar errores o daños en los documentos
                  electrónicos, datos o sistemas físicos y lógicos de la
                  empresa, o de terceras personas; así como obstaculizar el
                  acceso de otros usuarios al sitio web y a sus servicios
                  mediante el consumo masivo de los recursos informáticos a
                  través de los cuales la empresa presta sus servicios.</p>
              </li>
              <li>
                <p class="parrafo">Intentar acceder a las cuentas de correo
                  electrónico de otros usuarios o a áreas restringidas de los
                  sistemas informáticos de la empresa o de terceros y, en su
                  caso, extraer información.</p>
              </li>
              <li>
                <p class="parrafo">Vulnerar los derechos de propiedad
                  intelectual o industrial, así como violar la confidencialidad
                  de la información de la empresa o de terceros.</p>
              </li>
              <li>
                <p class="parrafo">Suplantar la identidad de otro usuario, de
                  las administraciones públicas, o de un tercero.</p>
              </li>
              <li>
                <p class="parrafo">Reproducir, copiar, distribuir, poner a
                  disposición o de cualquier otra forma comunicar públicamente,
                  transformar o modificar los contenidos, a menos que se cuente
                  con la autorización del titular de los correspondientes
                  derechos o ello resulte legalmente permitido.</p>
              </li>
              <li>
                <p class="parrafo">Recabar datos con finalidad publicitaria y de
                  remitir publicidad de cualquier clase y comunicaciones con
                  fines de venta u otras de naturaleza comercial sin que medie
                  su previa solicitud o consentimiento.</p>
              </li>
            </ul>
            <p class="parrafo">Todos los contenidos del sitio web, como textos,
              fotografías, gráficos, imágenes, iconos, tecnología, software, así
              como su diseño gráfico y códigos fuente, constituyen una obra cuya
              propiedad pertenece a sus respectivos titulares, sin que puedan
              entenderse cedidos al usuario ninguno de los derechos de
              explotación sobre los mismos más allá de lo estrictamente
              necesario para el correcto uso de la web.</p>
            <p class="parrafo">En definitiva, los usuarios que accedan a este
              sitio web pueden visualizar los contenidos y efectuar, en su caso,
              copias privadas autorizadas (solicitando y obteniendo autorización
              expresa de la empresa), siempre que los elementos reproducidos no
              sean cedidos posteriormente a terceros, ni se instalen a
              servidores conectados a redes, ni sean objeto de ningún tipo de
              explotación.</p>
            <p class="parrafo">Asimismo, todas las marcas, nombres comerciales o
              signos distintivos de cualquier clase que puedan aparecer en el
              sitio web son propiedad de sus respectivos dueños, sin que pueda
              entenderse que el uso o acceso al mismo atribuya al usuario
              derecho alguno sobre los mismos.</p>
            <p class="parrafo">La distribución, modificación, cesión o
              comunicación pública de los contenidos y cualquier otro acto que
              no haya sido expresamente autorizado por el titular de los
              derechos de explotación quedan prohibidos. El establecimiento de
              un hiperenlace no implica en ningún caso la existencia de
              relaciones entre la empresa y el propietario del sitio web en la
              que se establezca, ni la aceptación y aprobación por parte de la
              empresa de sus contenidos o servicios.</p>
            <p class="parrafo">Aquellas personas que se propongan establecer un
              hiperenlace previamente deberán solicitar autorización por escrito
              a la empresa. En todo caso, el hiperenlace únicamente permitirá el
              acceso a la página de inicio de nuestro sitio web, asimismo deberá
              abstenerse de realizar manifestaciones o indicaciones falsas o
              inexactas sobre la empresa, o incluir contenidos ilícitos,
              contrarios a las buenas costumbres y al orden público.La empresa
              no se responsabiliza del uso que cada usuario haga de los
              materiales puestos a disposición de este sitio web ni de las
              actuaciones que realice en base a los mismos.</p>
          </div>
          <div class="about-content mt-3">
            <h2 class="m-3">Exclusión de garantías y responsabilidad</h2>
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <p class="parrafo">El contenido del presente sitio web es de
              carácter general y tiene una finalidad meramente instructiva, sin
              que se garantice plenamente el acceso a todos los contenidos, ni
              su exhaustividad, corrección, vigencia o actualidad, ni su
              idoneidad o utilidad para un objetivo específico distinto del
              original para el que fue concebido. La empresa excluye, hasta
              donde permite el ordenamiento jurídico, cualquier responsabilidad
              por los daños y perjuicios de toda naturaleza derivados de:</p>
            <ul>
              <li>
                <p class="parrafo">La imposibilidad de acceso al sitio web o la
                  falta de veracidad, exactitud, exhaustividad y/o actualidad de
                  los contenidos, así como la existencia de vicios y defectos de
                  toda clase de los contenidos transmitidos, difundidos,
                  almacenados, puestos
                  a disposición a los que se haya accedido a través del sitio
                  web o de los servicios que se ofrecen.</p>
              </li>
              <li>
                <p class="parrafo">La presencia de virus o de otros elementos
                  malware en los contenidos que puedan producir alteraciones en
                  los sistemas informáticos, documentos electrónicos o datos de
                  los usuarios.</p>
              </li>
              <li>
                <p class="parrafo">El incumplimiento de las leyes, la buena fe,
                  el orden público, los usos del tráfico y el presente aviso
                  legal como consecuencia del uso incorrecto del sitio web. En
                  particular, y a modo de ejemplificativo, la empresa no se hace
                  responsable de las actuaciones de terceros que vulneren los
                  derechos de propiedad intelectual e industrial, derechos al
                  honor, la intimidad personal y familiar y a la propia
                  imagen.</p>
              </li>
            </ul>
            <p class="parrafo">Asimismo, la empresa declina cualquier
              responsabilidad respecto a la información que se halle fuera de
              esta web y no sea gestionada directamente por nuestro
              administrador del sitio. La función de los enlaces que aparecen en
              esta web es exclusivamente la de informar al usuario sobre la
              existencia de otras fuentes susceptibles de ampliar los contenidos
              que ofrece este sitio web.</p>
          </div>
          <div class="about-content mt-3">
            <h2 class="m-3">Procedimiento en caso de realización de actividades
              de carácter ilícito</h2>
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <p class="parrafo">En el caso de que cualquier usuario o un tercero
              considere que existen hechos o circunstancias que revelen el
              carácter ilícito de la utilización de cualquier contenido y/o la
              realización de cualquier actividad de las páginas web incluidas o
              accesibles a través del sitio web https://diegoreinaldos.com/,
              deberá enviar una notificación a info@diegoreinaldos.com
              identificándose debidamente, especificando las supuestas
              infracciones y declarando expresamente y bajo su responsabilidad
              que la información proporcionada en la notificación es exacta.
              Para toda cuestión litigiosa que incumba al sitio web de la
              empresa, será de aplicación la legislación española, siendo
              competentes los Juzgados y Tribunales del domicilio de la
              empresa.</p>
          </div>

          <div class="about-content mt-3">
            <h2 class="m-3">Notificaciones</h2>
            <!--<h6>We can support student forum 24/7 for national and international students.</h6>-->
            <p class="parrafo">Todas las notificaciones y comunicaciones entre
              los usuarios se considerarán eficaces, a todos los efectos, cuando
              se realicen a través de correo postal, correo electrónico, o
              comunicación telefónica. Los usuarios deberán dirigirse a la
              empresa mediante cualquiera de los medios de contacto indicados al
              inicio del presente aviso legal.</p>
            <p class="parrafo">El presente aviso legal ha sido revisado en Julio
              de 2023, por lo que pueden existir variaciones hasta su próxima
              revisión.</p>
          </div>

        </div>
      </div>
    </div>
  </div>

</app-page-container-publico>