<div id="breadcumb" class="breadcrumb-container position-relative" [ngStyle]="{'background-image': 'url(' + '../../../../assets/' + nombre_imagen+ ')' }">
    <div class="container text-container">
      <p class="p-0">
        <a *ngFor="let item of rutas; index as i" [routerLink]="i === rutas.length -1 ? null : item.enlace ">
          {{ item.ruta }}
        </a>
      </p>
      <h3 *ngIf="nombre_breadcrumb !== ''" class="p-0 m-0">{{nombre_breadcrumb}}</h3>
      <h3 *ngIf="nombre_breadcrumb === ''" class="p-0 m-0">{{rutas[rutas.length - 1].ruta}}</h3>
    </div>
</div>
