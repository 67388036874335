<div class="container py-5">
  <div class="product-container">
    <div class="product-details">
      <h3>{{ producto.titulo}}</h3>
      <div class="referencia my-3">
        <p class="text-center">Ref: {{ producto.referencia }}</p>
      </div>
      <div class="product-descript">
        <p>{{ producto.descripcion }}</p>
      </div>
      
    </div>
    <div class="product-imgs">
      <app-producto-content-tipo-uno-slider [imgs]="producto.imagenes" />
    </div>
  </div>
  <div class="product-share">
    <h5>Compartir: </h5>
    <div class="icon-container">
      <a target="_blank" [href]="enlace_whatsapp">
        <fa-icon class="icono-whatsapp" [icon]="icono_whatsapp" />
      </a>
    </div>
  </div>
</div>
