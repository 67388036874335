<div class="header-top__container container">
  <div class="header-top__container-left">
    <a  class="header-top__link" href="tel:{{telefono}}">
      <div class="d-flex">
        <fa-icon [icon]="icono_telefono" />
        <div class="text-container">
          <p class="not-visible__mobile texto-little">Contacto</p>
          <p>{{ telefono }}</p>
        </div>
      </div>
    </a>
   
    <a class="header-top__link" href="">
      <div class="d-flex">
        <fa-icon [icon]="icono_whatsapp" />
        <div class="text-container">
          <p class="not-visible__mobile">Whatsapp</p>
          <p>{{ telefono_whatsapp }}</p>
        </div>
      </div>
    </a>
   
    <a class="header-top__link not-visible__mobile" href="">
      <div class="d-flex">
        <fa-icon [icon]="icono_telefono" />
        <div class="text-container">
          <p class="not-visible__mobile p-0">Donde estamos</p>
          <p class="p-0">Puerto Lumbreras, Murcia</p>
        </div>
      </div>
    </a>
   
    <a class="header-top__link not-visible__mobile not-visible__tablet" href="">
      <div class="d-flex">
        <fa-icon [icon]="icono_telefono" />
        <div class="text-container">
          <p class="not-visible__mobile p-0">Email: </p><br>
          <a class="p-0 header-top__link" href="mailto:info@diegoreinaldos.com">info@diegoreinaldos.com</a>
        </div>
      </div>
    </a>
   
  </div>
  <div class="header-top__container-right">
    <button class="button_idioma" (click)="cambia_idioma_clicked()">
      <div class="d-flex justify-content-center align-content-center w-100">
        <img class="flag" [src]="img_src" />
        <div class="text-container">
          <p class="p-0 m-0">{{ idioma_label }}</p>
        </div>
      </div>
    </button>
    <ul class="idioma__list" [ngClass]="{'visible': selector_idioma_activo === true}">
      <ng-container *ngFor="let idioma of idiomas_disponibles; let i = index ">
        <li class="idioma__item"  class="idioma__item" (click)="selecciona_idioma(idioma_seleccionables[i])">
          <img class="flag" alt="spanish_flag" [src]="idiomas_banderas[i]">
          <p>{{idioma}}</p>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
