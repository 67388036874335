
<div class="form-group ">
    <label for="{{id}}">{{ label }}</label>
    <textarea 
        class="form-control" 
        [id]="id"
        [class.invalid]="control.invalid && control.dirty && control.touched"
        [formControl]="control"
        [placeholder]="placeholder"
        rows="7"></textarea>
  </div>

<!--<editor
[id]="id"
[class.invalid]="control.invalid && control.dirty && control.touched"
[formControl]="control"
apiKey="6sqygp06o8idj4wfv3ic9aysw3unljpzn6e81z1drgq1zbia"
[init]="{
    height: height,
    placeholder: placeholder,
    menubar: false,
    plugins: ['anchor', 'wordcount'],
    toolbar:
    'undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | help'
}" />-->
<ng-container class="position-relative" *ngFor="let err of control.errors | keyvalue">
<div class="position-absolute error-message" [ngClass]="{'not-show' : !control.touched && control.invalid}">
    <p>
    {{errorMessages[err.key]}}
    </p>
</div>
</ng-container>
  