import { Component, Input } from '@angular/core';
import {  } from '@fortawesome/angular-fontawesome';
import { faCalendar, faEnvelope, faMapLocation } from '@fortawesome/free-solid-svg-icons';
import { CardIconoTextoEnlaceModel } from 'src/app/models/entity/card_icono_texto_enlace_tipo_uno.model';

@Component({
  selector: 'app-card-icono-texto-enlace-tipo-uno',
  templateUrl: './card-icono-texto-enlace-tipo-uno.component.html',
  styleUrls: ['./card-icono-texto-enlace-tipo-uno.component.scss']
})
export class CardIconoTextoEnlaceTipoUnoComponent {
  @Input()item!: CardIconoTextoEnlaceModel;
 
}
