import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-breadcrumb-container",
  templateUrl: "./breadcrumb-container.component.html",
  styleUrls: ["./breadcrumb-container.component.scss"],
})
export class BreadcrumbContainerComponent {
  @Input() nombre_breadcrumb: string = "";
  @Input() nombre_imagen: string = "fondo_antiguedades.png";
  @Input() tiene_id_url: boolean = false;
  ruta_activa: string;
  breadcrumbs: string[] = [];
  rutas: { ruta: string; enlace: string }[] = [];
  constructor(private _router: Router) {
    // Obtiene ruta activa
    this.ruta_activa = _router.url;
    this.obtener_breadcrumb();
  }

  obtener_breadcrumb() {
    // Comprueba si no es la raiz
    if (this.ruta_activa !== "/") {
      // Obtiene las rutas en un array
      const rutas = this.ruta_activa.split("/");
      // Genera breadcrumb para inicio
      this.rutas.push({ ruta: "Inicio", enlace: "/" });
      // Genera breadcrumb para busqueda
      if (rutas[1].includes("busqueda") && rutas[1].includes("?")) {
        this.rutas.push({ ruta: "Búsqueda", enlace: ""})
        this.nombre_breadcrumb = "Búsqueda"
        return
      }
      rutas.forEach((item: string, index: number) => {
        // Comprobamos la palabra catálogo por poner la tilde en pantalla
        if (item === "catalogo") {
          this.rutas.push({ ruta: "Catálogo", enlace: "/catalogo" });
          // Si tiene guiones vamos a formatear el texto
        } else if (item.includes("-")) {
          // Formateo de texto
          const nuevo = item.replaceAll("-", " ");
          let enlace = "";
          // Obtención de enlace
          for (let i = 0; i < index; i++) {
            enlace += `/${rutas[i]}`;
          }
          // Inserción del enlace
          this.rutas.push({
            ruta: nuevo[0].toLocaleUpperCase() + nuevo.substring(1),
            enlace: index === rutas.length - 1 ? "" : enlace,
          });
        } else if (item !== "") {
          let enlace = "";
          // Obtención de enlace
          for (let i = 0; i < index; i++) {
            enlace += `/${rutas[i]}`;
          }
          this.rutas.push({
            ruta: item.toLocaleUpperCase()[0] + item.substring(1),
            enlace: index === rutas.length - 1 ? "" : enlace,
          });
        }
        this.rutas.push({ ruta: "/", enlace: "" });
      });
      // Elimina la última ruta que será un slash
      this.rutas.pop();
    }
  }

  ngOnInit() {
    if (this.nombre_breadcrumb !== "") {
      this.rutas.pop();
    }
  }
}
