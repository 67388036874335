import { Component } from '@angular/core';
import { globalState } from 'src/global';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-quienes-somos',
  templateUrl: './quienes-somos.component.html',
  styleUrls: ['./quienes-somos.component.scss']
})
export class QuienesSomosComponent {
  idioma: string = globalState.idioma_usuario

  constructor(private titleService: Title, private metaService: Meta) {
  }

  ngOnInit(): void {
    // Configurar el título de la página
    this.titleService.setTitle('Quiénes Somos - Antigüedades Diego Reinaldos');

    // Configurar meta etiquetas
    this.metaService.addTags([
      {
        name: 'description',
        content: 'Conozca a Antigüedades Diego Reinaldos, expertos en la restauración y venta de antigüedades. Ofrecemos piezas únicas con historia y calidad garantizada.'
      },
      {
        name: 'keywords',
        content: 'Antigüedades Diego Reinaldos, restauración de antigüedades, venta de antigüedades, piezas únicas, historia, calidad garantizada, expertos en antigüedades, muebles antiguos, objetos de colección, arte antiguo'
      },
     
    ]);
  }
}
