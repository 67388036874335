import { IUsuario } from './../interface/usuario.interface';


export class UsuarioModel implements IUsuario {
  uid: number;
  nivel: number;
  idioma: string;
  email: string;
  password: string;
  nombre: string;
  telefono: string;
  descripcion: string;
  fecha_alta?: string | null;
  fecha_consulta?: string | null;
  fecha_modificacion?: string | null;
  usuario_alta: number;
  usuario_consulta: number;
  usuario_modificacion: number;
  consultas: number;
  modificaciones: number;
  logged: number;
  fecha_acceso: string;
  clave: string;

  constructor(
    uid: number,
    nivel: number,
    idioma: string,
    email: string,
    password: string,
    nombre: string,
    telefono: string,
    descripcion: string,
    usuario_alta: number,
    usuario_consulta: number,
    usuario_modificacion: number,
    consultas: number,
    modificaciones: number,
    logged: number,
    fecha_acceso: string,
    clave: string,
    fecha_alta?: string | null,
    fecha_consulta?: string | null,
    fecha_modificacion?: string | null
  ) {
    this.uid = uid;
    this.nivel = nivel;
    this.idioma = idioma;
    this.email = email;
    this.password = password;
    this.nombre = nombre;
    this.telefono = telefono;
    this.descripcion = descripcion;
    this.fecha_alta = fecha_alta ?? null;
    this.fecha_consulta = fecha_consulta ?? null;
    this.fecha_modificacion = fecha_modificacion ?? null;
    this.usuario_alta = usuario_alta;
    this.usuario_consulta = usuario_consulta;
    this.usuario_modificacion = usuario_modificacion;
    this.consultas = consultas;
    this.modificaciones = modificaciones;
    this.logged = logged;
    this.fecha_acceso = fecha_acceso;
    this.clave = clave;
  }
}
