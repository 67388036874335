import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { CalleModel } from 'src/app/models/entity/calle.model';
import { SolicitudModel } from 'src/app/models/entity/solicitud.model';
import { createSolicitudView } from '../helpers/model.helper';
import { SolicitudView } from 'src/app/models/entity/views/solicitudes.view';
import { EditaSolicitudView } from 'src/app/models/entity/views/edita_solicitud.view';
import { ModelSlider } from 'src/app/models/entity/slider.model';
import { IActualizaSlider, IInsertaSlider } from 'src/app/models/interface/slider.interface';
import { IActualizaSubfamilia } from 'src/app/models/interface/subfamilia.interface';
import { obtainToken } from '../helpers/session.helper';

@Injectable()
export class SliderServices {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router) { }

  async ficha(id: number): Promise<ModelSlider> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}sliders/${id}`, httpOptions));
      return new ModelSlider(
        data.id_slider,
        data.titulo,
        data.activo,
        data.orden,
        data.texto,
        data.link,
        data.imagen);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  async lista(): Promise<ModelSlider[]> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}sliders`, httpOptions));
      const slider: ModelSlider[] = [];
      data.forEach((item: any) => {
        slider.push(new ModelSlider(
          item.id_slider,
          item.titulo,
          item.activo,
          item.orden,
          item.texto,
          item.link,
          item.imagen
        ))
      })
      return slider;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la solicitud';
      return Promise.reject(errorMessage);
    }
  }

  async inserta (datos: IInsertaSlider): Promise<ModelSlider> {
    const http_options = {
      headers: new HttpHeaders({ Accept: 'application/json' })
    };
    const params = new FormData();
    params.append('titulo', datos.titulo);
    params.append('texto', datos.texto);
    params.append('enlace', datos.link);
    params.append('activo', datos.activo ? '1' : '0');
    params.append('orden', datos.orden.toString());
    params.append('archivo', datos.imagen);
    params.append('imagen', datos.archivo);

    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}sliders`, params, http_options))
      return new ModelSlider(
        data.id_slider,
        data.titulo,
        data.activo,
        data.orden,
        data.texto,
        data.link,
        data.imagen
      )
    } catch(e: any) {
      const error_message = e?.error?.message || 'Error al insertar el slider';
      return Promise.reject(error_message); 
    }
  }
  async actualiza(id:number, datos: IActualizaSlider): Promise<ModelSlider> {
    const http_options = {
      headers: new HttpHeaders({ Accept: 'application/json'})
    };
    const params = new FormData();
    params.append('id', id.toString());
    params.append('titulo', datos.titulo);
    params.append('texto', datos.texto);
    params.append('enlace', datos.link);
    params.append('activo', datos.activo ? '1':'0');
    params.append('orden', datos.orden.toString());
    params.append('archivo', datos.imagen );
    if (datos.archivo !== undefined) {
      params.append('imagen', datos.archivo);
    }
    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}sliders/actualiza`, params, http_options));
      return new ModelSlider(
        data.id_slider,
        data.titulo,
        data.activo,
        data.orden,
        data.texto,
        data.link,
        data.imagen
      );
    } catch(e: any) {
      const error_message = e?.error?.message || 'Error al actualizar el slider';
      return Promise.reject(error_message)
    }
  }

  async elimina(id_articulo: number): Promise<Boolean> {
    const token = obtainToken();
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }).set('Authorization', 'Bearer ' + token),
    };
    try {
      const res = await firstValueFrom(this._http.delete<any>(`${this.url}sliders/${id_articulo}`, httpOptions));
      return true;
    } catch (e: any) {
      return false;
    }
  }
}
