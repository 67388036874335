import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { createUsuario } from '../helpers/model.helper';
import { IInsertaUsuario, IUsuario } from 'src/app/models/interface/usuario.interface';

@Injectable()
export class UsuariosService {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;
  public tableName: string = 'usuarios';

  constructor(private _http: HttpClient, private _router: Router,) {
  }


  ficha(id: number): Observable<any> {
    const params = new HttpParams()
      .set('id', id + '')

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + this.tableName + '/ficha', params, httpOptions);
  }

  // Realiza el login del usuario
  lista(): Observable<any> {
    const params = new HttpParams()
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + this.tableName + '/lista', params, httpOptions);
  }

  // Realiza el login del usuario
  listapag(pagina: number, registros: number, filtro: string): Observable<any> {
    const params = new HttpParams()
      .set('pagina', pagina + '')
      .set('registros', registros + '')
      .set('filtro', filtro);
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(
      this.url + this.tableName + '/listapag',
      params,
      httpOptions
    );
  }

  // Realiza el login del usuario
  async lista_paginacion(pagina: number, registros: number, filtro: string): Promise<{ usuarios: UsuarioModel[], pagina_actual: number, total_paginas: number }> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const res = await firstValueFrom(this._http.get<any>(`${this.url}usuarios?pagina=${pagina}&registros=${registros}&filtro=${filtro}`, httpOptions));
      const list_usuarios: UsuarioModel[] = [];
      res.data.usuarios.forEach((item: any) => {
        list_usuarios.push(createUsuario(item))
      })
      const { pagina_actual, total_paginas } = res.data
      return { usuarios: list_usuarios, pagina_actual, total_paginas };
    } catch (e: any) {
      console.log(e);
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }

  // Realiza el login del usuario
  total(filtro: string): Observable<any> {
    const params = new HttpParams()
      .set('filtro', filtro);
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };
    return this._http.post<any>(this.url + this.tableName + '/total', params, httpOptions);
  }


  async crea_nuevo(datos: IInsertaUsuario): Promise<UsuarioModel> {

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}usuarios`, datos, httpOptions))
      return createUsuario(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear el usuario';
      return Promise.reject(errorMessage);
    }
  }

  async actualiza_nuevo(id:number, datos: IInsertaUsuario): Promise<UsuarioModel> {

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      const { data } = await firstValueFrom(this._http.put<any>(`${this.url}usuarios/${id}`, datos, httpOptions))
      return createUsuario(data);
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear el usuario';
      return Promise.reject(errorMessage);
    }

  }

  // Realiza el login del usuario
  existeEmail(email: string): Observable<any> {
    const params = new HttpParams()
      .set('email', email)

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + this.tableName + '/existeEmail', params, httpOptions);
  }

  mailPassword(id: number, email: string | null): Observable<any> {
    const params = new HttpParams()
      .set('id', id + '')
      .set('email', email ?? '');

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + this.tableName + '/mailpassword', params, httpOptions);
  }

  changePassword(id: number, password: string | null): Observable<any> {
    const params = new HttpParams()
      .set('id', id + '')
      .set('password', password ?? '');

    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      params: params,
    };

    return this._http.post(this.url + this.tableName + '/cambiaclave', params, httpOptions);
  }

  // Realiza el login del usuario
  async ficha_nuevo(id: number): Promise<UsuarioModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}usuarios/${id}`, httpOptions));
      return new UsuarioModel(
        data.uid,
        data.nivel,
        data.idioma,
        data.email,
        data.password,
        data.nombre,
        data.telefono,
        data.descripcion,
        data.usuario_alta,
        data.usuario_consulta,
        data.usuario_modificacion,
        data.consulta,
        data.modificaciones,
        data.logged,
        data.fecha_acceso,
        data.clave,
        data.fecha_alta,
        data.fecha_consulta,
        data.modificacion
      );
    } catch (e: any) {
      console.log(e);
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }
  // Realiza el login del usuario
  async lista_nuevo(): Promise<UsuarioModel[]> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.get<any>(`${this.url}usuarios`, httpOptions));
      const lista: UsuarioModel[] = [];
      data.forEach((item: IUsuario) => {
        lista.push(createUsuario(item))
      })
      return lista;
    } catch (e: any) {
      console.log(e);
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }

  async elimina(id: number): Promise<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };

    try {
      await firstValueFrom(this._http.delete<any>(`${this.url}usuarios/${id}`, httpOptions))
      return true;
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al crear la zona';
      return Promise.reject(errorMessage);
    }
  }
}
