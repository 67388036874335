import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { IInsertaZona } from 'src/app/models/interface/zona.interface';
import { globalState } from 'src/global';

@Component({
  selector: 'app-intranet-zonas-form',
  templateUrl: './intranet-zonas-form.component.html',
  styleUrls: ['./intranet-zonas-form.component.scss']
})
export class IntranetZonasFormComponent {
  @Input() es_nuevo!: boolean;
  @Input() id!: number;
  @Input() zona!: ZonasModel | null;
  @Output() envia_nuevo_usuario: EventEmitter<IInsertaZona> = new EventEmitter();
  @Output() envia_actualiza_usuario:EventEmitter<IInsertaZona> = new EventEmitter();

  formGroup = new FormGroup({
    idioma: new FormControl<string>('', [Validators.required]),
    zona: new FormControl<string>('', [Validators.required]),
    zona_en: new FormControl<string>(''),
    zona_fr: new FormControl<string>(''),
  });


  constructor( private _toast: ToastrService){}

  ngOnInit() {
    if (!this.es_nuevo && this.zona) {
      this.rellenar_formulario(this.zona);
    }
  }


  rellenar_formulario(zona: ZonasModel) {
    this.formGroup.patchValue({
      idioma: zona.idioma,
      zona: zona.zona,
      zona_en: zona.zona_en,
      zona_fr: zona.zona_fr
    });
  }


  on_submit() {
    const valido = this.comprueba_formulario();
    if (valido) {
      const datos_zona = this.formatea_inserta_usuario();
      if (this.es_nuevo) {
        this.envia_nuevo_usuario.emit(datos_zona)
      } else {
        this.envia_actualiza_usuario.emit(datos_zona);
      }
    }
  }


  comprueba_formulario(): boolean {
    const { idioma, zona } = this.formGroup.value

    if (idioma === null || idioma === undefined || idioma === '') {
      this._toast.error("El campo idioma es obligatorio");
      return false
    }

    if (zona === null || zona === undefined || zona === '') {
      this._toast.error("El campo zona es obligatorio");
      return false
    }

    return true;
  }


  formatea_inserta_usuario(): IInsertaZona {
    return {
      idioma: this.formGroup.value.idioma!!,
      zona: this.formGroup.value.zona!!,
      zona_en: this.formGroup.value.zona_en!!,
      zona_fr: this.formGroup.value.zona_fr!!,
      usuario_alta: globalState.identity!!.uid
    };
  }
}
