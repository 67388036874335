import { Component, OnInit } from '@angular/core';
import { globalState } from 'src/global';
import { ConfiguracionService } from 'src/app/services/https/configuracion.services';
import { obtainToken, obtainVersion, saveVersion } from './services/helpers/session.helper';
import { AuthService } from './services/https/auth.services';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    ConfiguracionService,
    AuthService
  ]
})
export class AppComponent {
  title = 'Diego Reinaldos - Antigüedades';
  constructor(
    private _auth_service: AuthService,
    private _router: Router
  ){
    globalState.identity = this._auth_service.getIdentity();
  }

  async ngOnInit() {
    // Comprobamos idioma y asignamos a una variable global y/o al token
    const idioma = navigator.language;
    const token_idioma = localStorage.getItem("session_idioma_diego_reinaldos");
    if (!token_idioma) {
      localStorage.setItem("session_idioma_diego_reinaldos", idioma);
      globalState.idioma_usuario = idioma;
    } else {
      globalState.idioma_usuario = token_idioma;
    }

    // Comprueba el token
    if (obtainToken() !== "") {
      try {
        const valido = await this._auth_service.comprueba_token();
        if (valido) {
          globalState.sesion_iniciada = true;
        }
      } catch(e) {
        if (e === "Token caducado") {
          localStorage.removeItem(globalState.tokenName);
          sessionStorage.removeItem(globalState.tokenName)
          globalState.token = undefined;
          this._router.navigate(["/"]);
        }
      }
    }

  }

}
