import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit{
  // Nombre sobre el input
  @Input() label!:string;
  // Control del campo dónde se define las propiedades y los validadores
  @Input() control = new FormControl();
  // Tipo de campo
  @Input() type!:string;
  // Id del campo
  @Input() id!:string;
  // Name del campo
  @Input() name!:string;
  // Placeholder para el campo
  @Input() placeholder:string =""
  // Permite desactivar el input
  @Input() disabled: boolean = false;
  @Input() required: boolean = true;
  // Hace que la verificación por aquí solo se active si se indica
  // Si no se indica siempre será falso
  @Input() isBlurValidation: boolean = false;
  @Input() maxLength: number = 0;
  // Es opcional y permite hacer una validación de campo por api,
  // el mensaje de error siempre a de estar dentro de error.message
  // @Input() obsValidation!: Observable<any>;
  // Permite modificar la incrementación cuando el type es number
  @Input() step:string =""
  // Si no se indica siempre será falso
  @Input() avisoRight: boolean = false;

  // Define los tipos y el mensaje de error a mostrar
  errorMessages: Record<string, string> = {
    required: "Este campo es obligatorio",
    email: "Este campo no es válido",
    minlength: "Este campo no es lo suficientemente largo",
    pattern: "Formato no válido",
    customError: ""
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  // Función que realiza la validación por api
  validation() {
    if (this.control.value !== '') {
      /*this.obsValidation.subscribe({
        next: (res) => {
          // this.control.setErrors({ customError: false})
        },
        error:(err) => {
          // Enviará un error personalizado cuando obtenga un error 409 (Conflict) de la petición
          // indicando el error dentro de error.message
          if (err.status === 409) {
            this.errorMessages['customError'] = err.error.message;
            this.control.setErrors({ customError: true})
          }
        },
      })*/
    }
  }
}
