/*
  Componente: table-default
  Version: 3.0
*/

import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faBell, faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import {
  faPencil,
  faPlus,
  faTrash,
  faFileUpload,
  faEye,
  faPrint,
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LoadService } from 'src/app/services/helpers/load.service';
import { BorradoServices } from 'src/app/services/https/borrado.services';
import { UploadServices } from 'src/app/services/https/upload.services';
import { globalState, tiposEstado, tiposValidado } from 'src/global';
import { FormControl } from '@angular/forms';
import { ActualizaServices } from 'src/app/services/https/actualiza.services';
import {
  generateRandomName,
  timeoutTime,
} from 'src/app/services/helpers/utils.helper';

import { TotalServices } from 'src/app/services/https/total.service';
import { TableField } from 'src/app/models/entity/tableField.model';
import { FiltroModel } from 'src/app/models/entity/filtro.model';
import { DocumentoModel } from 'src/app/models/entity/documento.model';

@Component({
  selector: 'app-table-default',
  templateUrl: './table-default.component.html',
  styleUrls: ['./table-default.component.scss'],
  providers: [
    BorradoServices,
    UploadServices,
    ActualizaServices,
    TotalServices,
  ],
})
export class TableDefaultComponent implements OnInit {
  // Datos a procesar en la tabla
  @Input() data!: any[];
  // Muestra o esconde el botón de nuevo
  @Input() ver_boton_nuevo: boolean = true;
  // Nombre de la propiedad a procesar en la tabla
  @Input() columnas_tabla!: string[];
  // Tipo de dato a procesar en la tabla
  @Input() tipo_dato_tabla!: string[];
  // Nombre de las columnas
  @Input() nombre_columnas!: string[];
  // Ancho de las columnas
  @Input() ancho_columnas!: string[];
  // Alineación de las columnas
  @Input() alineacion_columnas!: string[];
  // Nombre de la tabla en BBDD
  @Input() nombre_tabla!: string;
  // Nombre para el campo primaryKey
  @Input() id_nombre: string = 'id';
  @Input() id_documentacion: string = 'solicitud_id'
  // Propiedad de información principal de los datos
  @Input() propiedad_principal: string = 'nombre';
  // Propiedades sobre las que se puede filtrar
  @Input() propiedades_para_filtro!: string[];
  // Ayuda para la redirección a otra página de creación
  @Input() ruta_nueva: string = '';
  // Ayuda para la redirección a otra página de edición
  @Input() ruta_edita: string = '';
  // Permite no mostrar la edición o eliminación de la solicitudes
  @Input() desactiva_acciones: boolean = false;
  // Permite no mostrar el botón de eliminar
  @Input() ver_boton_borrar: boolean = false;
  // Permite no mostrar el botón de eliminar
  @Input() ver_boton_editar: boolean = false;
  // Permite mostrar el botón de descargar
  @Input() ver_boton_descargar: boolean = false;
  // Permite mostrar el botón de subirArchivo
  @Input() ver_boton_subir: boolean = false;
  // Permite mostrar el botón de subirArchivo
  @Input() ver_boton_imprimir: boolean = false;
  // Permite desplegar un popUp para editar el campo observaciones
  @Input() ver_editar_observaciones: boolean = false;
  // Filtros dinámicos, debe recibir un máximo de 2 objetos
  @Input() filtro_dinamico_principal!: FiltroModel[];
  // Botón para mostrar de forma dinámica un modal fuera de la vista
  @Input() ver_boton_modal_externo: boolean = false;
  // Texto del botón
  @Input() texto_modal_externo: string = '';
  // Valor para abrir el modal
  @Output() valores_modal_externo: EventEmitter<boolean> = new EventEmitter();
  // Envío de datos para impresión de tarjeta
  @Output() enviar_datos_impresion: EventEmitter<any> = new EventEmitter();
  @Output() envia_borrado: EventEmitter<number> = new EventEmitter();
  @Output() recarga_datos: EventEmitter<boolean> = new EventEmitter();

  // Parametros paginacion
  @Input() tiene_paginacion: boolean = false;
  @Input() total_paginas!: number;
  @Input() pagina_actual: number = 1;
  @Output() envia_pagina: EventEmitter<number> = new EventEmitter();
  @Output() envia_filtro: EventEmitter<{filtro_texto: string, filtro_dinamico: string}> = new EventEmitter();
  @Input() ver_acciones: Boolean = true;
  @Input() ver_barra_superior: Boolean = true;
  @Input() total_datos = 0;
  @Input() texto_total = '';
  queryFiltro: string = '';


  // Iconos a mostrar
  editaIcon: IconDefinition = faPencil;
  nivel: number | undefined = globalState.identity?.nivel;
  eliminaIcon: IconDefinition = faTrash;
  nuevoIcon: IconDefinition = faPlus;
  nuevoObs: IconDefinition = faSquarePlus;
  downloadIcon: IconDefinition = faEye;
  uploadIcon: IconDefinition = faFileUpload;
  observacionIcon: IconDefinition = faBell;
  impresionIcon: IconDefinition = faPrint;
  faAngleLeft: IconDefinition = faAngleLeft;
  faAngleRight: IconDefinition = faAngleRight;
  faAnglesLeft: IconDefinition = faAnglesLeft;
  faAnglesRight: IconDefinition = faAnglesRight;

  // Mostrar labels de validado
  validadosLabel = tiposValidado;

  // Datos que se usarán como copia para asegurar que no se pierda la información de la tabla al realizar una búsqueda
  originalData: any[] = [];
  // Transforma el array tableColumns en un array bidimensional donde cada columna puede venir con varios campos separados por #
  dataFields: any[] = [];
  // Valor del campo de búsqueda
  filtro_texto: string = '';
  // Indica porque campo debe buscar, se incializa en propiedad_principal, que es el que le ha indicado inicialmente
  searchBy: string = this.propiedad_principal;
  // Apoyo para obtener nombre e id que se van a usar en la subida de archivo
  selectedUpload: { nombre: string; id: number } | null = null;
  // Apoyo para obtener la información de la observación a mostrar
  selectedObservacion: number = 0;
  //totalPaginas : number = 1;
  filtrando: boolean = true;
  // Apoyo a la subida de archivo
  fileName: string = '';
  uploadEvent: any;
  // Muestra de archivos en el campo tinyEditor, o texto con estilos
  observacionesContent: SafeHtml = '';
  observacionesEdit: FormControl = new FormControl<string>('', []);
  observacionesEditUsuario: FormControl = new FormControl<string>('', []);
  controlFilterFirst: FormControl = new FormControl<number>(0, []);
  controlFilterSeccond: FormControl = new FormControl<number>(0, []);
  // Tipos de estado
  tiposEstado = tiposEstado;

  constructor(
    private modalService: NgbModal,
  ) {}

  /**
   * Inicializa el componente y da valor a original data
   **/
  ngOnInit(): void {
    this.originalData = this.data;
    this.searchBy = this.propiedad_principal;
    this.inicializaCampos();
  }

  inicializaCampos() {
    // Transforma el array tableColumns en un array bidimensional donde cada columna puede venir con varios campos separados por #
    // traspasa adataFields cada columna a un array que puede contaner varios campos
    this.columnas_tabla.forEach((element, index) => {
      let fields: any[];
      let types: any[];
      let values: any[];
      let aligns: any[];
      let tableFields: TableField[] = [];

      fields = element.split('#')
      types = this.tipo_dato_tabla[index].split('#');
      aligns = this.alineacion_columnas[index].split('#')
      let newD = new TableField('','','','');
      fields.forEach((field, position) => {
        newD = new TableField(
          '',
          types[position] ?? '',
          aligns[position] ?? '',
          field ?? ''
        )
        tableFields.push(newD);
      });
      this.dataFields.push(tableFields);
    });
  }

  pulsa_borrar(id: number) {
    this.envia_borrado.emit(id)
  }

  /**
   * Filtra según el dato proporcionado por searchBy
   */
  filtrarTabla() {
    // Verifica si se está utilizando paginación
    if (this.tiene_paginacion) {
      // Si es así, emite el evento 'envia_filtro' con el texto de búsqueda actual
      this.envia_filtro.emit({filtro_texto: this.filtro_texto, filtro_dinamico: this.queryFiltro});
    } else {
      // Si no se está utilizando paginación
      // Restaura los datos originales
      this.data = this.originalData;

      // Si hay un texto de búsqueda
      if (this.filtro_texto) {
        // Filtra los datos originales para encontrar coincidencias
        this.data = this.originalData.filter((item) => {
          // Comprueba si alguna de las propiedades especificadas contiene el texto de búsqueda
          return this.propiedades_para_filtro.some((property) => {
            // Convierte el valor de la propiedad a minúsculas y verifica si incluye el texto de búsqueda
            return item[property]
              .toString()
              .toLowerCase()
              .includes(this.filtro_texto.toLowerCase());
          });
        });
      }
    }
  }

  /**
   * Abre el modal del subida de archivos
   
  abrir_subir_archivo(id: number) {
    const modal_ref = this.modalService.open(ModalSubirArchivoComponent, { centered: true });
    modal_ref.componentInstance.id_documento = id;
  }


  abrir_edita_observaciones(observaciones: string, id: number) {
    const modal_ref = this.modalService.open(ModalEditaObservacionesComponent);
    modal_ref.componentInstance.id = id;
    modal_ref.componentInstance.observaciones = observaciones
    modal_ref.componentInstance.es_administrador = globalState.identity?.nivel!! > 0;
    modal_ref.componentInstance.guardado.subscribe((item: boolean) => {
      if (item)
        this.recarga_datos.emit(item);
      modal_ref.close();
    })
  }

  abrir_ver_observaciones(observaciones: string) {
    const modal_ref = this.modalService.open(ModalVerObservacionesComponent);
    modal_ref.componentInstance.observaciones = observaciones
  }*/


  /**
   * Filtrado por JS sin petición HTTP
   * @param position
   * @param event
   */
  filtroDinamico(position: number, event: any) {
    this.data = this.originalData;
    // Recoge el objecto
    const object = this.filtro_dinamico_principal!![position];
    // Asigna el indice
    object.selectedIndex = event.target.value;

    const filterValue = event.target.value;
    this.creaFiltro();

    if (filterValue != object.defaultSelectValue) {
      /*this.data = this.data.filter((item) => {
        const value = item[filterBy].toString();
        return value.includes(filterValue);
      });*/
    }
  }

  creaFiltro() {
    // Inicizalizamos la query
    this.queryFiltro = '';
    this.filtro_dinamico_principal!!.forEach((item) => {
      if (item.selectedIndex > 0) {
        if (this.queryFiltro !== '') {
          this.queryFiltro += ' and ';
        }
        this.queryFiltro +=
          ' (' +
          item.campo +
          ' = ' +
          item.lista[item.selectedIndex - 1].id +
          ')';
      }
    });
    this.envia_filtro.emit({filtro_texto: this.filtro_texto, filtro_dinamico: this.queryFiltro});
  }

  /**
   * Abre el modal externo
   */
  pulsa_modal_externo() {
    this.valores_modal_externo.emit(true);
  }

  recibePagina(pagina: number) {
    this.pagina_actual = pagina;
    this.envia_pagina.emit(pagina);
  }
}
