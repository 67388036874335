import { IConfigGeneral } from './../interface/configuracion.interface';

export class ConfigGeneralModel implements IConfigGeneral {
  id: number;
  horario1: string;
  horario1_en: string;
  horario1_fr: string;
  horario2: string;
  horario2_en: string;
  horario2_fr: string;
  quienessomos: string;
  fotoquienessomos: string;
  telefono: string;
  telefono_whatsapp: string;

  constructor(
    id: number,
    horario1: string,
    horario1_en: string,
    horario1_fr: string,
    horario2: string,
    horario2_en: string,
    horario2_fr: string,
    quienessomos: string,
    fotoquienessomos: string,
    telefono: string,
    telefono_whatsapp: string
  ) {
    this.id = id;
    this.horario1 = horario1;
    this.horario2 = horario2;
    this.quienessomos = quienessomos;
    this.fotoquienessomos = fotoquienessomos;
    this.telefono = telefono;
    this.telefono_whatsapp = telefono_whatsapp;
    this.horario1_en = horario1_en;
    this.horario1_fr = horario1_fr;
    this.horario2_en= horario2_en;
    this.horario2_fr = horario2_fr;
  }


}
