import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BorradoModalComponent } from 'src/app/components/common/borrado-modal/borrado-modal.component';
import { FamiliaModel } from 'src/app/models/entity/familia.model';
import { IActualizaFamilia, IInsertaFamilia } from 'src/app/models/interface/familias.interface';
import { LoadService } from 'src/app/services/helpers/load.service';
import { FamiliaServices } from 'src/app/services/https/familia.services';
import { SubfamiliaServices } from 'src/app/services/https/subfamilia.services';

@Component({
  selector: 'app-intranet-familias-page',
  templateUrl: './intranet-familias-page.component.html',
  styleUrls: ['./intranet-familias-page.component.scss'],
  providers: [FamiliaServices, SubfamiliaServices]
})
export class IntranetFamiliasPageComponent {
  es_listado: Boolean;
  familia: FamiliaModel | null = null;
  id: number = 0;
  data: FamiliaModel[] = [];
  registros: number = 100;
  pagina_actual: number = 1;
  total_paginas: number = 0;
  filtro: string = '';
  cargando: boolean = true;

  constructor(
    private _router: Router,
    private _loader: LoadService,
    private _toast: ToastrService,
    private _modal_service: NgbModal,
    private _subfamilia_service: SubfamiliaServices,
    private _familia_service: FamiliaServices,
    private titleService: Title,
    private metaService: Meta
  ) {
    const { url } = this._router
    const segments = url.split('/');
    this.es_listado = segments.length === 3;
    if (!this.es_listado)
      if (segments[segments.length - 1] !== "nuevo") {
        this.id = Number(segments[segments.length - 1]);
      } else {
        this.cargando = false;
      }
  }

  async ngOnInit(): Promise<void> {
    // Configurar el título de la página
    this.titleService.setTitle('Intranet - Antigüedades Diego Reinaldos');
    // Configurar meta etiquetas
    this.metaService.addTags([
    ]);
    if (this.es_listado) {
      try {
        this._loader.notifyLoadChange(true)
        const { total_paginas, pagina_actual, familias } = await this._familia_service.paginacion(this.pagina_actual, this.registros, this.filtro);
        this.data = familias;
        this.total_paginas = total_paginas;
        this.pagina_actual = pagina_actual;
      } catch (e: any) {
        console.log(e)
      } finally {
        this._loader.notifyLoadChange(false)
      }
    } else {
      if (this.id !== 0) {
        try {
          this._loader.notifyLoadChange(true);
          this.familia = await this._familia_service.ficha(this.id)
        } catch (e: any) {

        } finally {
          this._loader.notifyLoadChange(false);
          this.cargando = false
        }
      }
    }
  }

  async obtener_listado(pagina_actual: number, total_registros: number, filtro: string): Promise<{ familias: FamiliaModel[], pagina_actual: number, total_paginas: number }> {
    return await this._familia_service.paginacion(pagina_actual, total_registros, filtro);
  }

  async obtiene_familia(id: number): Promise<FamiliaModel> {
    try {
      return await this._familia_service.ficha(id);
    } catch (e: any) {
      console.log(e);
      return Promise.reject(e);
    }
  }

  async recibe_filtro(filtro_recibido: any) {
    const { filtro_texto } = filtro_recibido
    const filtro = `WHERE (INSTR(articulo, '${filtro_texto}') > 0) OR (INSTR(proveedor, '${filtro_texto}') > 0) OR (INSTR(descripcion, '${filtro_texto}') > 0)`;
    const pagina = this.filtro === '' ? 1 : this.pagina_actual;
    this.filtro = filtro
    const { familias, total_paginas, pagina_actual } = await this.obtener_listado(pagina, this.registros, filtro);
    this.data = familias;
    this.total_paginas = total_paginas;
    this.pagina_actual = pagina_actual;
  }

  async recibe_pagina(pagina_recibida: number) {
    this.pagina_actual = pagina_recibida;
    const { familias, total_paginas, pagina_actual } = await this.obtener_listado(this.pagina_actual, this.registros, this.filtro);
    this.data = familias;
    this.total_paginas = total_paginas;
    this.pagina_actual = pagina_actual;
  }

  abrir_modal_borrado(id: number) {
    const modalRef = this._modal_service.open(BorradoModalComponent, { centered: true })
    modalRef.componentInstance.tipo_objeto = "familia"
    modalRef.componentInstance.id = id
    modalRef.componentInstance.tabla = "familias"
    modalRef.componentInstance.borrado_exitoso.subscribe(async (borrado_exitoso: boolean) => {
      if (borrado_exitoso) {
        try {
          this._loader.notifyLoadChange(true);
          await this._familia_service.elimina(id);
        } catch (e: any) {
          this._toast.error(e);
          this._loader.notifyLoadChange(false);
        } finally {
          this.ngOnInit();
        }
      }
    });
  }

  async recibe_nueva_familia(evento: IInsertaFamilia) {
    try {
      this._loader.notifyLoadChange(true);
      const familia = await this._familia_service.inserta(evento);
      this._toast.success('Familia creada con éxito');
      this._router.navigate(['/intranet/familias'])
    } catch (e: any) {
      console.log(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  async recibe_actualiza_familia(evento: IActualizaFamilia) {
    try {
      this._loader.notifyLoadChange(true);
      const familia = await this._familia_service.actualiza(this.id, evento)
      if (familia !== null) {
        this._toast.success('Familia actualizada con éxito');
        this._router.navigate(['/intranet/familias'])
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

}
