import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { globalState } from 'src/global';
import { UsuarioModel } from 'src/app/models/entity/usuario.model';
import { ArticuloModel } from 'src/app/models/entity/articulos.model';
import { IArticulos } from 'src/app/models/interface/articulo.interface';
import { obtainToken } from '../helpers/session.helper';

@Injectable()
export class ArticuloServices {
  public url: string = globalState.url;
  public identity: UsuarioModel | undefined = globalState.identity;
  public token: undefined | string = globalState.token;

  constructor(private _http: HttpClient, private _router: Router) { }
  
  async paginacion(pagina: number, registros: number, filtro: string): Promise<{ articulos: ArticuloModel[], pagina_actual: number, total_paginas: number }> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const res = await firstValueFrom(this._http.get<any>(`${this.url}articulos?pagina=${pagina}&registros=${registros}&filtro=${filtro}`, httpOptions));
      const lista_articulos: ArticuloModel[] = [];
      res.data.articulos.forEach((item: IArticulos) => {
        lista_articulos.push(
          new ArticuloModel(
            item
        ));
      });
      const { pagina_actual, total_paginas } = res.data;
      return { articulos: lista_articulos, pagina_actual, total_paginas };
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }
  
  async ficha(id_articulo: number): Promise<ArticuloModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const res = await firstValueFrom(this._http.get<any>(`${this.url}articulos/${id_articulo}`, httpOptions));
      return new ArticuloModel(res.data)
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }
  
  async elimina(id_articulo: number): Promise<Boolean> {
    const token = obtainToken();
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }).set('Authorization', 'Bearer ' + token),
    };
    try {
      const res = await firstValueFrom(this._http.delete<any>(`${this.url}articulos/${id_articulo}`, httpOptions));
      return true;
    } catch (e: any) {
      return false;
    }
  }

  async inserta_articulo(
    zona_uid:number, articulo: string, articulo_en: string,
    articulo_fr:string, datos_proveedor: string, descripcion: string,
    descripcion_en:string, descripcion_fr: string, familia_uid: number,
    subfamilia_uid:number, file_name: string, stock: number, imagen: File,
    referencia: number, precio_compra: string, precio_1: string, precio_2: string,
    keywords: string
  ): Promise<ArticuloModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const formData = new FormData();
    formData.append('zona_uid', zona_uid.toString());
    formData.append('articulo', articulo);
    formData.append('articulo_en', articulo_en);
    formData.append('articulo_fr', articulo_fr);
    formData.append('referencia', referencia.toString());
    formData.append('precio_compra', precio_compra);
    formData.append('precio_1', precio_1);
    formData.append('precio_2', precio_2);
    formData.append('file_type', imagen.type);
    formData.append('file_size', '0');
    formData.append('datos_proveedor', datos_proveedor);
    formData.append('descripcion', descripcion);
    formData.append('descripcion_en', descripcion_en);
    formData.append('descripcion_fr', descripcion_fr);
    formData.append('familia_uid', familia_uid.toString());
    formData.append('subfamilia_uid', subfamilia_uid.toString());
    formData.append('file_name', file_name);
    formData.append('stock', stock.toString());
    formData.append('imagen', imagen);
    formData.append('keywords', keywords);
    formData.append('usuario_alta', globalState.identity?.uid.toString() || '0');
    try {
      const res = await firstValueFrom(this._http.post<any>(`${this.url}articulos`, formData, httpOptions));
      return  new ArticuloModel( res.data );
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al insertar un artículo';
      return Promise.reject(errorMessage);
    }
  }
  

  async actualiza_articulo(
    uid: number, zona_uid:number, articulo: string, articulo_en: string,
    articulo_fr:string, datos_proveedor: string, descripcion: string,
    descripcion_en:string, descripcion_fr: string, familia_uid: number,
    subfamilia_uid:number, file_name: string, stock: number, 
    referencia: number, precio_compra: string, precio_1: string, precio_2: string,
    keywords: string, imagen: File | undefined  = undefined
  ): Promise<ArticuloModel> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    const formData = new FormData();
    formData.append('uid', uid.toString());
    formData.append('zona_uid', zona_uid.toString());
    formData.append('articulo', articulo);
    formData.append('articulo_en', articulo_en);
    formData.append('articulo_fr', articulo_fr);
    formData.append('referencia', referencia.toString());
    formData.append('precio_compra', precio_compra);
    formData.append('precio_1', precio_1);
    formData.append('precio_2', precio_2);
    formData.append('file_size', '0');
    formData.append('datos_proveedor', datos_proveedor);
    formData.append('descripcion', descripcion);
    formData.append('descripcion_en', descripcion_en);
    formData.append('descripcion_fr', descripcion_fr);
    formData.append('familia_uid', familia_uid.toString());
    formData.append('subfamilia_uid', subfamilia_uid.toString());
    formData.append('file_name', file_name);
    formData.append('stock', stock.toString());
    formData.append('keywords', keywords);
    formData.append('usuario_alta', globalState.identity?.uid.toString() || '0');
    
    if (imagen !== undefined) {
      formData.append('imagen', imagen);
      formData.append('file_type', imagen.type);
    }
    try {
      const res = await firstValueFrom(this._http.post<any>(`${this.url}articulos/actualiza`, formData, httpOptions));
      return  new ArticuloModel( res.data );
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al insertar un artículo';
      return Promise.reject(errorMessage);
    }
  }

  async total_articulos(): Promise<number> {
    const httpOptions = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
    };
    try {
      const { data } = await firstValueFrom(this._http.post<any>(`${this.url}articulos/numero_articulos`, httpOptions));
      return data
    } catch (e: any) {
      const errorMessage = e?.error?.message || 'Error al obtener la lista';
      return Promise.reject(errorMessage);
    }
  }
  
}
