import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InicioPageComponent } from './pages/publico/inicio-page/inicio-page.component';
import { UsuarioAccess } from './services/guards/usuarioAccess.guard';
import { AuthService } from './services/https/auth.services';
import { AdminAccess } from './services/guards/adminAcces.guard';
import { InspectorAccess } from './services/guards/inspectorAcces.guard';
import { CatalogoPageComponent } from './pages/publico/catalogo-page/catalogo-page.component';
import { ProductoPageComponent } from './pages/publico/producto-page/producto-page.component';
import { QuienesSomosComponent } from './pages/publico/quienes-somos/quienes-somos.component';
import { ContactoPageComponent } from './pages/publico/contacto-page/contacto-page.component';
import { LoginPageComponent } from './pages/privado/login-page/login-page.component';
import { IntranetArticulosPageComponent } from './pages/privado/intranet-articulos-page/intranet-articulos-page.component';
import { IntranetFamiliasPageComponent } from './pages/privado/intranet-familias-page/intranet-familias-page.component';
import { IntranetSubfamiliasComponent } from './pages/privado/intranet-subfamilias/intranet-subfamilias.component';
import { IntranetNovedadesComponent } from './pages/privado/intranet-novedades/intranet-novedades.component';
import { IntranetSliderComponent } from './pages/privado/intranet-slider/intranet-slider.component';
import { IntranetUsuariosComponent } from './pages/privado/intranet-usuarios/intranet-usuarios.component';
import { IntranetZonasComponent } from './pages/privado/intranet-zonas/intranet-zonas.component';
import { IntranetConfiguracionPageComponent } from './pages/privado/intranet-configuracion-page/intranet-configuracion-page.component';
import { AvisoLegalPageComponent } from './pages/publico/aviso-legal-page/aviso-legal-page.component';
import { PoliticaPrivacidadPageComponent } from './pages/publico/politica-privacidad-page/politica-privacidad-page.component';
import { PoliticaCookiesPageComponent } from './pages/publico/politica-cookies-page/politica-cookies-page.component';


const routes: Routes = [
  // Públicas
  { path: '', component:  InicioPageComponent},
  { path: 'catalogo', component:  CatalogoPageComponent},
  { path: 'busqueda', component:  CatalogoPageComponent},
  { path: 'catalogo/:familia', component:  CatalogoPageComponent},
  { path: 'catalogo/:familia/:subfamilia', component:  CatalogoPageComponent},
  { path: 'catalogo/:familia/:subfamilia/:id_producto', component:  ProductoPageComponent},
  { path: 'quienes-somos', component:  QuienesSomosComponent},
  { path: 'contacto', component:  ContactoPageComponent},
  { path: 'login', component:  LoginPageComponent },
  { path: 'aviso', component:  AvisoLegalPageComponent },
  { path: 'aviso/politica', component:  PoliticaPrivacidadPageComponent },
  { path: 'aviso/politica/cookies', component:   PoliticaCookiesPageComponent},

  // Privadas
  { path: 'intranet/articulos', component:  IntranetArticulosPageComponent, canActivate:[AdminAccess]  },
  { path: 'intranet/articulos/:id', component:  IntranetArticulosPageComponent, canActivate:[AdminAccess] },
  { path: 'intranet/familias', component:  IntranetFamiliasPageComponent, canActivate:[AdminAccess] },
  { path: 'intranet/familias/:id', component:  IntranetFamiliasPageComponent, canActivate:[AdminAccess] },
  { path: 'intranet/subfamilias', component:  IntranetSubfamiliasComponent, canActivate:[AdminAccess] },
  { path: 'intranet/subfamilias/:id', component:  IntranetSubfamiliasComponent, canActivate:[AdminAccess] },
  { path: 'intranet/novedades', component:  IntranetNovedadesComponent, canActivate:[AdminAccess] },
  { path: 'intranet/novedades/:id', component:  IntranetNovedadesComponent, canActivate:[AdminAccess] },
  { path: 'intranet/slider', component:  IntranetSliderComponent, canActivate:[AdminAccess] },
  { path: 'intranet/slider/:id', component:  IntranetSliderComponent, canActivate:[AdminAccess] },
  { path: 'intranet/usuarios', component:  IntranetUsuariosComponent, canActivate:[AdminAccess] },
  { path: 'intranet/usuarios/:id', component:  IntranetUsuariosComponent, canActivate:[AdminAccess] },
  { path: 'intranet/zonas', component:  IntranetZonasComponent, canActivate:[AdminAccess] },
  { path: 'intranet/zonas/:id', component:  IntranetZonasComponent, canActivate:[AdminAccess] },
  { path: 'intranet/configuracion', component:  IntranetConfiguracionPageComponent, canActivate:[AdminAccess] },
  // { path: 'intranet/configuracion', component:  IntranetComponent },
  // Redirección para rutas no existentes
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule],
  providers: [AuthService, UsuarioAccess, AdminAccess, InspectorAccess]
})
export class AppRoutingModule { }
