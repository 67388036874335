import { IZona } from "../interface/zona.interface";

export class ZonasModel implements IZona{
  uid: number;
  idioma: string;
  zona: string;
  zona_en: string;
  zona_fr: string;
  fecha_alta: string;
  fecha_consulta: string | null;
  fecha_modificacion: string | null;
  consultas: number;
  modificaciones: number;

  constructor(
    uid: number,
    idioma: string,
    zona: string,
    zona_en: string,
    zona_fr: string,
    fecha_alta: string,
    fecha_consulta: string | null,
    fecha_modificacion: string | null,
    consultas: number,
    modificaciones: number,

  ) {
    this.uid = uid;
    this.idioma = idioma;
    this.zona = zona;
    this.zona_en = zona_en;
    this.zona_fr = zona_fr;
    this.fecha_alta = fecha_alta;
    this.fecha_consulta = fecha_consulta;
    this.fecha_modificacion = fecha_modificacion;
    this.consultas = consultas;
    this.modificaciones = modificaciones;
  }
}
