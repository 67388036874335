import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faWhatsapp, IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faMapLocation, faMessage, faPhone } from '@fortawesome/free-solid-svg-icons';
import { ConfiguracionService } from 'src/app/services/https/configuracion.services';
import { globalState } from 'src/global';

@Component({
  selector: 'app-page-container-publico',
  templateUrl: './page-container-publico.component.html',
  styleUrls: ['./page-container-publico.component.scss'],
  providers: [ConfiguracionService]
})
export class PageContainerPublicoComponent {
  @Input() title: string = '';
  @Output() envia_datos_cfg: EventEmitter<{horario1: string, horario1_en: string, horario1_fr: string,horario2: string, horario2_en: string, horario2_fr: string, telefono: string, telefono_whatsapp: string}> = new EventEmitter()
  icono_mapa: IconDefinition = faMapLocation;
  icono_telefono: IconDefinition = faPhone;
  icono_whatsapp: IconDefinition = faWhatsapp;
  icono_email: IconDefinition = faMessage;
  iniciada_sesion: boolean = globalState.sesion_iniciada;
  telefono: string = "";
  telefono_whatsapp: string = "";
  horario1: string = "";
  horario2: string = "";
  cargando: boolean = true;
  constructor(
    private _service: ConfiguracionService
  ) {}

  async ngOnInit() {
    try {
      this.cargando = true;
      const { horario1, horario1_en, horario1_fr, horario2, horario2_en, horario2_fr, telefono, telefono_whatsapp } = await this._service.ficha_nueva(1);
      this.horario1 = horario1;
      this.horario2 = horario2;
      this.telefono = telefono;
      this.telefono_whatsapp = telefono_whatsapp;
      this.envia_datos_cfg.emit({horario1, horario1_en, horario1_fr, horario2, horario2_en, horario2_fr, telefono, telefono_whatsapp})
    } catch (e: any) {
      console.log(e);
    } finally {
      this.cargando = false;
    }
  }

}
