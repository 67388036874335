<div class="position-relative w-100" [ngClass]="{'sticky-header': es_fijo}">
  <!-- Desktop-->
  <div class="container">
    <div class="gap-5 pt-3 pb-3 menu-desktop clear-fix px-3 container">
      <div class="">
        <a routerLink="/">
          <img
            class="header-img"
            src="../../../../../assets/a_r_logo.png"
            alt="logo-limusa"
          />
        </a>
      </div>
      <nav class="header-links-container">
        <a
          nivel="button"
          [ngClass]="{ selected: rutaActiva === '/' }"
          class="header-link-item"
          [routerLink]="'/'"
          >Inicio</a
        >
        <a
          [routerLink]="'/catalogo'"
          nivel="button"
          [ngClass]="{ selected: rutaActiva === '/catalogo' }"
          class="header-link-item"
          >Catálogo</a
        >
        <a
          nivel="button"
          [ngClass]="{ selected: rutaActiva === '/quienes-somos' }"
          [routerLink]="'/quienes-somos'"
          class="header-link-item"
          >Quienes Somos</a
        >
        <a
          nivel="button"
          [routerLink]="'/contacto'"
          [ngClass]="{ selected: rutaActiva === '/contacto' }"
          class="header-link-item"
          >Contacto</a
        >
        <a
          *ngIf="identity !== undefined"
          nivel="button"
          [routerLink]="'/intranet/articulos'"
          [ngClass]="{ selected: rutaActiva === '/intranet/articulos' }"
          class="header-link-item"
          >Intranet</a
        >
      </nav>
    </div>

    <!-- Mobile -->
    <div class="menu-mobile">
      <!-- Menu mobile-->
      <div class="menu-mobile-abs" [ngClass]="{ active: mobileMenuActive }">
        <div class="menu-mobile__header underline-border">
          <div class="d-flex justify-content-between pr-3">
            <img
              class="logo-mobile"
              src="../../../../../assets/a_r_logo.png"
              alt="logo-diego-reinaldos"
            />
            <fa-icon
              [icon]="menuCloseIcon"
              (click)="toggleMenuMobile()"
            ></fa-icon>
          </div>
        </div>
        <div class="menu-mobile__body">
          <nav class="header-links-container">
            <a [ngClass]="{'selected' : rutaActiva === '/'}" class="header-link-item" href="">Inicio</a>
            <a class="header-link-item" [routerLink]="'/catalogo'">Catálogo</a>
            <a class="header-link-item" [routerLink]="'/empresa'" href="">Quienes Somos</a>
            <a routerLink="login" [routerLink]="'/contacto'" class="header-link-item">Contacto</a>
          </nav>
        </div>
      </div>
      <!-- Fin del menu-->
      <a routerLink="/">
        <img
          class="logo-mobile"
          src="../../../../../assets/a_r_logo.png"
          alt="logo-limusa"
        />
      </a>
      <a class="header-link-item" [routerLink]="'/catalogo'">Catálogo</a>
      <fa-icon (click)="toggleMenuMobile()" [icon]="menuIcon"></fa-icon>
    </div>
  </div>
</div>
