<app-intranet-container>
  
  <app-table-default *ngIf="es_listado"
  [data]="data"
  [columnas_tabla]="[
    'referencia',
    'articulo',
    'descripcion',
    'stock',
    'precio_compra',
    'precio_1',
    'precio_2',
  ]"
  [nombre_columnas]="[
    'Referencia',
    'Nombre',
    'Descripcion',
    'Stock',
    'Precio compra',
    'Precio 1',
    'Precio 2',
  ]"
  [tipo_dato_tabla]="[
    '',
    '',
    '',
    '',
    '',
    'money',
    'money',
    'money',
  ]"
  [alineacion_columnas]="[
    'start#start',
    'start#start',
    'start#start',
    'start#start',
    'start#start',
    'start#start',
    'center#start',
    'center#start',
    ]"
  [ancho_columnas]="[]"
  [id_nombre]="'uid'"
  [ver_boton_modal_externo]="false"
  [ver_boton_editar]="true"
  [ver_boton_borrar]="true"
  [ver_boton_imprimir]="false"
  [propiedades_para_filtro]="['articulo','referencia','descripcion']"
  [nombre_tabla]="'articulos'"
  [tiene_paginacion] = "true"
  [pagina_actual] = "pagina_actual"
  [total_paginas]="total_paginas"
  [total_datos]="total_articulos"
  [texto_total]="'Articulos con stock: '"
  (envia_filtro)="recibe_filtro($event)"
  (envia_pagina)="recibe_pagina($event)"
  (envia_borrado)="abrir_modal_borrado($event)"
  
  />

  <app-intranet-articulos-page-form *ngIf="!es_listado && cargando === false"
    [articulo]="articulo"
    [familias]="familias"
    [subfamilias]="subfamilias"
    [zonas]="zonas"
    [id]="id"
    [imagenes_articulo]="imagenes_articulos"
    (familia_seleccionada)="obtener_subfamilias($event)"
    (envia_nuevo_articulo)="recibe_nuevo_articulo($event)"
    (envia_actualiza_articulo)="recibe_actualiza_articulo($event)"
  />
</app-intranet-container>