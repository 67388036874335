<form class="form-container"  #articulo_form="ngForm" (ngSubmit)="on_submit()">
  <div class="form-header mt-4 d-flex justify-content-between">
    <h6 *ngIf="es_nuevo" class="mb-0">Registro de Novedad</h6>
    <h6 *ngIf="!es_nuevo" class="mb-0">Actualización de Novedad</h6>
  </div>
  <div class="form-body-three-cols mt-3">
    <app-input
      class="mt-3"
      [control]="formGroup.controls.novedad"
      [label]="'Novedad *'"
      [type]="'text'"
      [placeholder]="'Ej: Puerta'"
      [required]="false"
      [id]="'nombre'" />
      <div class="form-group pt-3">
        <label for="idioma">Idioma *</label>
        <select
          [class.invalid]="formGroup.controls.idioma.invalid && formGroup.controls.idioma.dirty && formGroup.controls.idioma.touched && formGroup.controls.idioma.value == ''"
          [formControl]="formGroup.controls.idioma"
          id="idioma"
          name="idioma"
          class="form-select">
          <option disabled value="">Selecciona idioma</option>
          <option value="es">Español</option>
          <option value="fr">Francés</option>
          <option value="en">Inglés</option>
        </select>
      </div>
      <app-input
      class="mt-3 full-width"
      [control]="formGroup.controls.descripcion"
      [label]="'Descripción *'"
      [type]="'text'"
      [placeholder]="'Ej: Puerta'"
      [required]="false"
      [id]="'nombre'" />
    <app-input
      class="mt-3 full-width"
      [control]="formGroup.controls.keywords"
      [label]="'Keywords'"
      [type]="'text'"
      [required]="false"
      [placeholder]="'Ej: Puerta'"
      [id]="'nombre'"/>

    <div class="mt-3 position-relative">
      <p class="">Imagen Principal</p>
      <img *ngIf="imagen_a_mostrar !== ''" class="img-fluid border" [src]="imagen_a_mostrar">
      <div class="pb-3">
      </div>
      <button (click)="borra_imagen();" *ngIf="formGroup.value.file_name !== '' && formGroup.value.file_name !== null && formGroup.value.file_name !== undefined" class="btn btn-danger position-absolute top-0 end-0">
        <fa-icon [icon]="icono_cerrar" />
      </button>
      <input
        type="file"
        class="form-control"
        style="max-height: 40px;"
        (change)="this.formatear_archivo($event)"
        accept="image/png, image/jpeg, application/pdf"
      />
    </div>
  </div>
  <div class="d-flex gap-3 justify-content-start">
    <button
      role="button"
      type="submit"
      class="btn btn-aceptar mt-5"
      >
      <span  *ngIf="es_nuevo">
        Crear
      </span>
      <span  *ngIf="!es_nuevo">
        Actualiza
      </span>
    </button>
    <a [routerLink]="'/intranet/familias'" class="btn btn-outline-error mt-5" >
      Cancelar
    </a>
  </div>
</form>
