<ngb-carousel
  #carousel
  [interval]="3000"
  [pauseOnHover]="pausa_al_pasar"
  [pauseOnFocus]="pausa_al_centrar"
>
  <ng-template ngbSlide *ngFor="let img of imgs; index as i">
    <div class="picsum-img-wrapper">
      <div class="slider-container">
        <img class="img-fluid text-center" style="height: 100%;" [src]="img" alt="">
      </div>
    </div>
  </ng-template>
</ngb-carousel>
