<form class="form-container" #articulo_form="ngForm" (ngSubmit)="on_submit()">
  <div class="form-header mt-4 d-flex justify-content-between">
    <h6 *ngIf="es_nuevo" class="mb-0">Registro de Zonas</h6>
    <h6 *ngIf="!es_nuevo" class="mb-0">Actualización de Zonas</h6>
  </div>
  <div class="form-body-three-cols mt-3">
    <app-input
      class="mt-3"
      [control]="formGroup.controls.zona"
      [label]="'Zona *'"
      [type]="'text'"
      [placeholder]="'Ej: Calle Principal'"
      [required]="true"
      [id]="'nombre'" />
    <app-input
      class="mt-3"
      [control]="formGroup.controls.zona_en"
      [label]="'Zona Inglés'"
      [type]="'text'"
      [placeholder]="'Ej: Calle Princial'"
      [required]="false"
      [id]="'zona_en'" />
    <app-input
      class="mt-3"
      [control]="formGroup.controls.zona_fr"
      [label]="'Zona Francés *'"
      [type]="'text'"
      [placeholder]="'Ej: Calle Principal'"
      [required]="false"
      [id]="'zona_fr'" />
    <div class="form-group pt-3">
      <label for="idioma">Idioma *</label>
      <select
        [class.invalid]="formGroup.controls.idioma.invalid && formGroup.controls.idioma.dirty && formGroup.controls.idioma.touched && formGroup.controls.idioma.value == ''"
        [formControl]="formGroup.controls.idioma"
        id="idioma"
        name="idioma"
        class="form-select">
        <option disabled value>Selecciona idioma</option>
        <option value="es">Español</option>
        <option value="fr">Francés</option>
        <option value="en">Inglés</option>
      </select>
    </div>
   </div>
  <div class="d-flex gap-3 justify-content-start">
    <button
      role="button"
      type="submit"
      class="btn btn-aceptar mt-5">
      <span *ngIf="es_nuevo">
        Crear
      </span>
      <span *ngIf="!es_nuevo">
        Actualiza
      </span>
    </button>
    <a [routerLink]="'/intranet/zonas'" class="btn btn-outline-error mt-5">
      Cancelar
    </a>
  </div>
</form>
