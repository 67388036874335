<div *ngIf="showBanner" class="cookie-banner">
  <p>
    Utilizamos cookies para mejorar su experiencia y analizar nuestro tráfico. Por favor, seleccione las categorías de cookies que acepta:
  </p>
  <div class="d-flex gap-5">
    <div>
      <input type="checkbox" id="analytics" [(ngModel)]="analyticsConsent">
      <label for="analytics">Cookies de estadísticas (Google Analytics)</label>
    </div>
    <div>
      <input type="checkbox" id="youtube" [(ngModel)]="youtubeConsent">
      <label for="youtube">Cookies de marketing (YouTube)</label>
    </div>
  </div>
  <div>
    <button (click)="acceptSelectedCookies()">Aceptar seleccionadas</button>
    <button class="btb btn-danger" (click)="rejectCookies()">Rechazar</button>
  </div>
</div>
