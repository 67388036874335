<div class="login-container">
  <div class="login__left">
    <img src="../../../../assets/login-bg.jpg" alt="Imagen fondo">
  </div>
  <div class="login__form">
    <div class="container">      
      <img class="img-fluid p-2" src="../../../../assets/a_r_logo.png" alt="Logo">
      <h3>Bienvenido</h3>
      <form class="login-form" (submit)="inicia_sesion()">
        <div class="mb-3">
          <app-input
          [label]="'Usuario'"
          [control]="form.controls.email"
          
          />
        </div>
        <div class="mb-4">
          <app-input
            [label]="'Contraseña'"
            [type]="'password'"
            [control]="form.controls.password"
          />
        </div>
        <button type="submit" class="btn btn-login" >Iniciar Sesión</button>
      </form>
    </div>
  </div>
</div>