<app-page-container-publico
  [title] = "'Inicio'"
>
  <section class="pt-0 pb-5">
    <app-slider [sliders]="sliders" />
  </section>
  <section class="pt-0 pb-5">
    <div class="container novedades">
      <h3 class="text-bold">Te asesoramos en cualquier proyecto</h3>
      <div class="three-cols pt-5">
        <ng-container *ngFor="let novedad of novedades">
          <div class="novedad-container">
            <a  [routerLink]="'busqueda'" [queryParams]="{ keyword: novedad.keywords}">
              <img class="img-fluid" [src]="novedad.file_name" [alt]="novedad.keywords">
            </a>
            <div class="novedad-content">
              <h3 class="cursor">
                <a class="novedad-container__title" [routerLink]="'busqueda'" [queryParams]="{ keyword: novedad.keywords }">
                  <b>
                    {{ novedad.novedad }}
                  </b>
                </a>
              </h3>
              <p class="novedad-container__desc">
                {{ novedad.descripcion }}
              </p>
            </div>
            <div class="btn-ver__container">
              <a [routerLink]="'busqueda'" [queryParams]="{ keyword: novedad.keywords}">Ver</a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
</app-page-container-publico>
