import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { ArticuloImagenModel } from 'src/app/models/entity/articulo_imagen.model';
import { ArticuloModel } from 'src/app/models/entity/articulos.model';
import { FamiliaModel } from 'src/app/models/entity/familia.model';
import { SubfamiliaModel } from 'src/app/models/entity/subfamilia.model';
import { ZonasModel } from 'src/app/models/entity/zonas.model';
import { LoadService } from 'src/app/services/helpers/load.service';
import { generateRandomName } from 'src/app/services/helpers/utils.helper';
import { ArticulosImagenesServices } from 'src/app/services/https/articulos_imagenes.services';
import { globalState } from 'src/global';

@Component({
  selector: 'app-intranet-articulos-page-form',
  templateUrl: './intranet-articulos-page-form.component.html',
  styleUrls: ['./intranet-articulos-page-form.component.scss'],
  providers: [ArticulosImagenesServices]
})
export class IntranetArticulosPageFormComponent {

  constructor(
    private _toast: ToastrService,
    private _loader: LoadService,
    private _articulo_imagenes_service: ArticulosImagenesServices
  ) { }

  @Input() id!: number;
  @Input() articulo!: ArticuloModel | null;
  @Input() familias!: FamiliaModel[];
  @Input() subfamilias!: SubfamiliaModel[];
  @Input() zonas!: ZonasModel[];
  @Input() imagenes_articulo: ArticuloImagenModel[] = [];
  @Output() familia_seleccionada: EventEmitter<number> = new EventEmitter();
  @Output() envia_nuevo_articulo: EventEmitter<{
    zona_uid: number,
    articulo: string,
    articulo_en: string,
    articulo_fr: string,
    datos_proveedor: string,
    descripcion: string,
    descripcion_en: string,
    descripcion_fr: string,
    familia_uid: number,
    subfamilia_uid: number,
    file_name: string,
    stock: number,
    form: File,
    referencia: number,
    precio_compra: string,
    precio_1: string,
    precio_2: string,
    keywords: string,
  }> = new EventEmitter();
  @Output() envia_actualiza_articulo: EventEmitter<{
    zona_uid: number,
    articulo: string,
    articulo_en: string,
    articulo_fr: string,
    datos_proveedor: string,
    descripcion: string,
    descripcion_en: string,
    descripcion_fr: string,
    familia_uid: number,
    subfamilia_uid: number,
    file_name: string,
    stock: number,
    form: File | undefined,
    referencia: number,
    precio_compra: string,
    precio_1: string,
    precio_2: string,
    keywords: string,
  }> = new EventEmitter();

  icono_cerrar: IconDefinition = faClose
  es_nuevo: boolean = false;
  url_imagenes: string = `${globalState.urlImg}articulos/`
  familia_enlace: string = "";
  subfamilia_enlace: string = "";
  imagen_articulo_form_data: File | null = null;
  imagen_a_mostrar: string = "";
  imagenes_articulo_form_data: any[] = [];

  formGroup = new FormGroup({
    uid: new FormControl<number>(0, []),
    zona_uid: new FormControl<number>(0, [Validators.required]),
    articulo: new FormControl<string>('', [Validators.required]),
    articulo_en: new FormControl<string>('', ),
    articulo_fr: new FormControl<string>('', ),
    consultas: new FormControl<number>(0, [Validators.required]),
    datos_proveedor: new FormControl<string>('', [Validators.required]),
    descripcion: new FormControl<string>('', [Validators.required]),
    descripcion_en: new FormControl<string>('', []),
    descripcion_fr: new FormControl<string>('', []),
    familia_uid: new FormControl<number>(0, [Validators.required]),
    subfamilia_uid: new FormControl<number>(0, [Validators.required]),
    file_name: new FormControl<string>('', [Validators.required]),
    stock: new FormControl<number>(0, [Validators.required]),
    referencia: new FormControl<number>(0, [Validators.required]),
    precio_compra: new FormControl<string>('0.00', [Validators.required]),
    precio_1: new FormControl<string>('0.00', [Validators.required]),
    precio_2: new FormControl<string>('0.00', [Validators.required]),
    keywords: new FormControl<string>('', [Validators.required])
  });

  ngOnInit() {
    if (this.id === 0) {
      this.es_nuevo = true;
    } else {
      this.rellenar_formulario();
    }
  }

  rellenar_formulario() {
    console.log(this.articulo)
    if (this.articulo !== null) {
      this.formGroup.patchValue({
        uid: this.articulo.uid,
        zona_uid: this.articulo.zona_uid,
        articulo: this.articulo.articulo,
        articulo_en: this.articulo.articulo_en,
        articulo_fr: this.articulo.articulo_fr,
        consultas: this.articulo.consultas,
        datos_proveedor: this.articulo.datos_proveedor,
        descripcion: this.articulo.descripcion,
        descripcion_en: this.articulo.descripcion_en,
        descripcion_fr: this.articulo.descripcion_fr,
        familia_uid: this.articulo.familia_uid,
        subfamilia_uid: this.articulo.subfamilia_uid,
        file_name: this.articulo.file_name,
        stock: this.articulo.stock,
        precio_compra: this.articulo.precio_compra,
        precio_1: this.articulo.precio_1,
        precio_2: this.articulo.precio_2,
        keywords: this.articulo.keywords,
        referencia: Number(this.articulo.referencia)
      });
      this.imagen_a_mostrar = this.articulo.file_name
    }
  }

  on_submit() {
    const valido = this.comprueba_formulario();
    if (valido === false) {
      return;
    }
    // Creación de formulario
    if (this.es_nuevo) {
      this.crear_nuevo_articulo();
    } else {
      this.actualizar_articulo();
    }
  }

  comprueba_formulario(): boolean {
    if (this.formGroup.value.zona_uid && this.formGroup.value.zona_uid === 0) {
      this._toast.error("Debes seleccionar una zona")
      return false;
    }
    if (this.formGroup.value.familia_uid && this.formGroup.value.familia_uid === 0) {
      this._toast.error("Debes seleccionar una familia")
      return false;
    }
    if (this.formGroup.value.subfamilia_uid && this.formGroup.value.subfamilia_uid === 0) {
      this._toast.error("Debes seleccionar una subfamilia")
      return false;
    }
    return true
  }

  crear_nuevo_articulo(): void {

    const { zona_uid, articulo, articulo_en, articulo_fr, datos_proveedor, descripcion, descripcion_en, descripcion_fr, familia_uid, subfamilia_uid, file_name, stock, referencia, precio_compra, precio_1, precio_2, keywords } = this.formGroup.value
    const articulo_data: {
      zona_uid: number,
      articulo: string,
      articulo_en: string,
      articulo_fr: string,
      datos_proveedor: string,
      descripcion: string,
      descripcion_en: string,
      descripcion_fr: string,
      familia_uid: number,
      subfamilia_uid: number,
      file_name: string,
      stock: number,
      form: File,
      referencia: number,
      precio_compra: string,
      precio_1: string,
      precio_2: string,
      keywords: string
    } = {
      zona_uid: zona_uid!!,
      articulo: articulo!!,
      articulo_en: articulo_en!!,
      articulo_fr: articulo_fr!!,
      datos_proveedor: datos_proveedor!!,
      descripcion: descripcion!!,
      descripcion_en: descripcion_en!!,
      descripcion_fr: descripcion_fr!!,
      familia_uid: familia_uid!!,
      subfamilia_uid: subfamilia_uid!!,
      file_name: file_name!!,
      stock: stock!!,
      form: this.imagen_articulo_form_data!!,
      referencia: referencia!!,
      precio_compra: precio_compra!!,
      precio_1: precio_1!!,
      precio_2: precio_2!!,
      keywords: keywords!!
    };
    this.envia_nuevo_articulo.emit(articulo_data);
  }

  async actualizar_articulo(): Promise<void> {
    const { zona_uid, articulo, articulo_en, articulo_fr, datos_proveedor, descripcion, descripcion_en, descripcion_fr, familia_uid, subfamilia_uid, file_name, stock, referencia, precio_compra, precio_1, precio_2, keywords } = this.formGroup.value
    const articulo_data: {
      zona_uid: number,
      articulo: string,
      articulo_en: string,
      articulo_fr: string,
      datos_proveedor: string,
      descripcion: string,
      descripcion_en: string,
      descripcion_fr: string,
      familia_uid: number,
      subfamilia_uid: number,
      file_name: string,
      stock: number,
      form: File | undefined,
      referencia: number,
      precio_compra: string,
      precio_1: string,
      precio_2: string,
      keywords: string
    } = {
      zona_uid: zona_uid!!,
      articulo: articulo!!,
      articulo_en: articulo_en!!,
      articulo_fr: articulo_fr!!,
      datos_proveedor: datos_proveedor!!,
      descripcion: descripcion!!,
      descripcion_en: descripcion_en!!,
      descripcion_fr: descripcion_fr!!,
      familia_uid: familia_uid!!,
      subfamilia_uid: subfamilia_uid!!,
      file_name: file_name!!,
      stock: stock!!,
      form: this.imagen_articulo_form_data ?? undefined,
      referencia: referencia!!,
      precio_compra: precio_compra!!,
      precio_1: precio_1!!,
      precio_2: precio_2!!,
      keywords: keywords!!
    };
    this.envia_actualiza_articulo.emit(articulo_data);
  }

  borra_imagen(): void {
    this.formGroup.patchValue({
      file_name: ''
    });
    this.imagen_a_mostrar = '';
  }

  cambia_familia(event: any) {
    this.familia_seleccionada.emit(event.target.value)
  }

  formatear_archivo(evento: any): void {
    const upload_event = evento.target;
    if (upload_event.files.length > 0) {
      // Solo gestionamos el primer archivo (puedes modificarlo para gestionar varios)
      const archivo: File = upload_event.files[0];
      const extension = archivo.name.split('.').slice(-1)[0].toLowerCase();

      // Genera un nombre aleatorio para el archivo
      const filename = `${generateRandomName()}.${extension}`;
      const archivo_modificado = new File([archivo], filename, { type: archivo.type })
      this.formGroup.patchValue({ file_name: filename });
      // Asigna el archivo formateado a la propiedad imagen_articulo

      this.imagen_articulo_form_data = archivo_modificado;

      // Verifica si es una imagen o un PDF para mostrar la vista previa
      if (['png', 'jpg', 'jpeg'].includes(extension)) {
        // Si es una imagen, genera una URL temporal para mostrar la vista previa
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imagen_a_mostrar = e.target.result

        };
        reader.readAsDataURL(archivo_modificado); // Lee el archivo como Data URL
      } else if (extension === 'pdf') {
        // Si es un PDF, también generamos una URL temporal
        const pdfUrl = URL.createObjectURL(archivo_modificado);
        this.formGroup.patchValue({
          file_name: pdfUrl // Establece la URL del PDF en el formulario
        });
      }
    }
  }

  async formatear_archivos_secundarios(evento: any): Promise<void> {
    const files: FileList = evento.target.files;
    try {
      this._loader.notifyLoadChange(true);
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const archivo = files[i];
          // Solo gestionamos el primer archivo (puedes modificarlo para gestionar varios)
          const extension = archivo.name.split('.').slice(-1)[0].toLowerCase();
  
          // Genera un nombre aleatorio para el archivo
          const filename = `${generateRandomName()}.${extension}`;
          const archivo_modificado = new File([archivo], filename, { type: archivo.type });
          const nueva_imagen = await this.envia_imagen_secundaria(archivo_modificado)
          console.log(nueva_imagen);
          this.imagenes_articulo.push(nueva_imagen);
        }
      }
    } catch (e: any) {
      console.log(e)
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }

  async envia_imagen_secundaria(archivo: File): Promise<ArticuloImagenModel>
  {
    try {
      return await this._articulo_imagenes_service.inserta(this.id, archivo)
    } catch (e: any) {
      return Promise.reject(e);
    }
  }

  async borra_imagen_secundaria(id: number): Promise<void>
  {
    try {
      this._loader.notifyLoadChange(true);
      await this._articulo_imagenes_service.borra(id)
      this.imagenes_articulo = this.imagenes_articulo.filter(articulo => articulo.uid !== id);
    } catch (e: any) {
      console.log(e)
    } finally {
      this._loader.notifyLoadChange(false);
    }
  }
}
