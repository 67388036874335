'use strict';

import { ConfigGeneralModel } from "./app/models/entity/configuracion.model";
import { UsuarioModel } from "./app/models/entity/usuario.model";

// Desarrollo
// export const urlBase = 'http://192.168.10.241:6996/diego_reinaldos';
// Produccion
export const urlBase = 'https://www.diegoreinaldos.com';

export interface IGlobalState {
  isLoading: boolean,
  tokenName : string,
  sesion_iniciada : boolean,
  token: undefined | string,
  identity: undefined | UsuarioModel,
  idioma_usuario: string,
  frontUrl: string
  url: string,
  urlImg: string,
  urlZonas: string,
  urlGeneral: string,
  registrosPagina: number
  annioActual: number,
  timeoutDelay: number,
  configuracion_global: ConfigGeneralModel | null,
}

export const globalState: IGlobalState = {
  isLoading: false,
  tokenName : 'token-reinaldos',
  sesion_iniciada: false,
  token: undefined,
  identity: undefined,
  idioma_usuario: "",

  // frontUrl: 'http://localhost:4200',
  // url: urlBase+'/',
  // urlImg: urlBase+'/uploads/',
  frontUrl: urlBase,
  url: urlBase+'/api/',
  urlImg: urlBase+'/api/uploads/',
  urlZonas: urlBase+'/api/uploads/zonas/',
  urlGeneral: urlBase+'/api/uploads/configuracion/',

  registrosPagina: 100,
  annioActual: 0,
  timeoutDelay: 500,
  configuracion_global: null
}


export const LIMU_VERSION: number = 101

export const tiposEstado = [
  {
    id: 1,
    descripcion: 'Pendiente de documentación',
  },
  {
    id: 2,
    descripcion: 'Pendiente de pago'
  },
  {
    id: 3,
    descripcion: 'Pagado'
  },
  {
    id: 4,
    descripcion: 'Rechazado'
  }
];


export const tiposValidado = {
  0: 'Pendiente de revisión',
  1: 'Validado',
  2: 'Rechazado'
} as const;

export const tiposnivel = [
  {
    id: 0,
    descripcion: 'Usuario',
  },
  {
    id: 1,
    descripcion: 'Inspector'
  },
  {
    id: 2,
    descripcion: 'Administrador'
  }
];


