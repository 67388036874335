import { IconDefinition } from "@fortawesome/free-brands-svg-icons"

export class CardIconoTextoEnlaceModel {
  titulo: string;
  icono: IconDefinition;
  enlace: string | null;
  nueva_pestana: boolean = false;
  descripcion: string | null;

  constructor(
    titulo: string, 
    icono: IconDefinition,
    enlace: string | null,
    nueva_pestana: boolean,
    descripcion: string | null
  ) {
    this.titulo = titulo;
    this.icono = icono;
    this.nueva_pestana = nueva_pestana
    this.enlace = enlace;
    this.descripcion = descripcion;
  }
}