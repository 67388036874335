<app-intranet-container>
  <app-table-default *ngIf="es_listado"
  [data]="data"
  [columnas_tabla]="[
    'uid',
    'novedad',
    'keywords',
    'idioma'
  ]"
  [nombre_columnas]="[
    'Referencia',
    'Nombre',
    'Keywords',
    'Idioma'
  ]"
  [tipo_dato_tabla]="[
    '',
    '',
    '',
    '',
    '',
  ]"
  [alineacion_columnas]="[
    'start#start',
    'start#start',
    'start#start',
    'start#start',
    ]"
  [ancho_columnas]="[
  ]"
  [ancho_columnas]="[]"
  [id_nombre]="'uid'"
  [ver_boton_modal_externo]="false"
  [ver_boton_editar]="true"
  [ver_boton_borrar]="true"
  [ver_boton_imprimir]="false"
  [propiedades_para_filtro]="['articulo','referencia','descripcion']"
  [nombre_tabla]="'articulos'"
  [tiene_paginacion] = "true"
  [pagina_actual] = "pagina_actual"
  [total_paginas]="total_paginas"
  [tiene_paginacion]="false"
  (envia_borrado)="abrir_modal_borrado($event)" 
  />
  <app-intranet-novedades-form *ngIf="!es_listado && cargando === false"
  [es_nuevo]="id == 0"
  [id]="id"
  [novedad]="novedad"
  (envia_nueva_novedad)="recibe_nueva_novedad($event)"
  (envia_actualiza_novedad)="recibe_actualiza_novedad($event)"
    />
</app-intranet-container>
  
