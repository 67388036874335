<div
  *ngIf="ver_barra_superior"
  class="d-flex form-group position-relative justify-content-between bg-secondary p-2 mt-3 table-options-top"
  style="--bs-bg-opacity: 0.1; border-radius: 5px"
>
  <a
    class="btn btn-aceptar"
    [ngStyle]="{'display':ver_boton_nuevo ? 'block':'none'}"
    [routerLink]="[ruta_nueva !== '' ? ruta_nueva : 'nuevo']"
  >
    <fa-icon [icon]="nuevoIcon"></fa-icon>
    <span class="ms-2">Nuevo</span>
  </a>
  <button
    *ngIf="ver_boton_modal_externo"
    (click)="pulsa_modal_externo()"
    class="btn btn-primary"
  >
    {{ texto_modal_externo }}
  </button>

  <!-- Filtros dinámicos -->
  <ng-container *ngIf="filtro_dinamico_principal">

    <div *ngFor="let filtro of filtro_dinamico_principal; index as i">
      <select
        [id]="filtro.id_nombre"
        [name]="filtro.id_nombre"
        (change)="filtroDinamico(i, $event)"
        class="form-select"
      >
        <option [value]="filtro.defaultSelectValue">
          {{ filtro.defaultSelectText }}
        </option>
        <option
          *ngFor="let option of filtro.lista"
          [value]="option[filtro.key]"
        >
          {{ option[filtro.princ] }}
        </option>
      </select>
    </div>

  </ng-container>

  <div class="select-bar">
    <input
      type="text"
      class="form-control"
      placeholder="Ejemplo: 23222547P"
      id="searchText"
      name="searchText"
      [(ngModel)]="filtro_texto"
      (input)="filtrarTabla()"
    />
  </div>

</div>

<!-- Barra de Navegacion -->
<paginator *ngIf="tiene_paginacion"
  class=""
  [pagina_actual]="pagina_actual"
  [total_paginas]="total_paginas"
  (cambia_pagina)="recibePagina($event)"
/>
<p *ngIf="total_datos > 0">{{ texto_total }} {{ total_datos }}</p>
<!-- Tabla -->
<div class="table-responsive">
  <table class="table table-striped table-sm table-hover">
    <thead class="thead-default">
      <tr>
        <th
          scope="col"
          class=""
          style="cursor: pointer"
          [ngStyle]="{ width: ancho_columnas[i] !== '' ? ancho_columnas[i] : '' }"
          *ngFor="let item of nombre_columnas; index as i"
        >
          {{ item }}
        </th>
        <th
          scope="col"
          [width]="'10%'"
          class="text-center"
          style="cursor: pointer"
          *ngIf="ver_acciones"
          >
          Acciones
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of data; let fila = index" >        <!-- Recorre por filas-->
        <td
          class="text-center"
          *ngFor="let cell of columnas_tabla | keyvalue; index as columna" [ngStyle]="{'backgroundColor': row.stock == 0 ? 'red' : 'inherit'}"
          
        >
          <!-- Mostrar los datos normalmente -->
          <app-item-table-string *ngFor="let campo of dataFields[columna]; index as j"
            [column]="j"
            [row]="fila"
            [data]="data"
            [tableField]="campo"
          />
          <!-- Tipo Modal 
          <ng-container *ngIf="tipo_dato_tabla[columna] === 'openModal'">
            <div
              *ngIf="
                ver_editar_observaciones &&
                columnas_tabla[columna] === 'observaciones' &&
                nivel !== undefined
              "
            >
              Botón de ver observaciones para el usuario
              <button
                style="margin-block: 1px; margin-left: 5px;  height: fit-content;"
                *ngIf="nivel === 0 && row[columnas_tabla[columna]] !== ''"
                class="btn btn-sm btn-warning"
                (click)="abrir_ver_observaciones(row['observaciones'])"
              >
                <fa-icon class="bell" [icon]="observacionIcon"></fa-icon>
              </button>

              <button
                style="margin-block: 1px; margin-left: 5px; height: fit-content;"
                *ngIf="nivel === 0"
                (click)="
                abrir_edita_observaciones(
                    row['observacionesUsuario'],
                    row[id_nombre]
                  )"
                class="btn btn-sm btn-warning ml-2"
              >
                <fa-icon [icon]="nuevoObs"></fa-icon>
              </button>

              <button
                *ngIf="nivel > 0"
                class="btn btn-sm btn-outline-primary"
                style="margin-block: 1px; margin-left: 5px;height: fit-content;"
                (click)="
                  abrir_edita_observaciones(
                    row[columnas_tabla[columna]],
                    row[id_nombre]
                  )"
              >
                <fa-icon class="bell" [icon]="observacionIcon"></fa-icon>
              </button>
              <span *ngIf="row[columnas_tabla[columna]] !== '' && nivel < 2">
                ¡Nueva observacion!
              </span>
            </div>
          </ng-container>-->
        </td>
        <td class="action-buttons">
          <!-- Botón de IMPRESION-->
          <button
            *ngIf="ver_boton_imprimir"
            style="margin-block: 1px; margin-left: 5px;height: fit-content;"
            [disabled]="desactiva_acciones"
            (click)="
            this.enviar_datos_impresion.emit(row);
            "
            class="btn btn-sm btn-secondary text-white mr-2"
          >
            <fa-icon [icon]="impresionIcon"></fa-icon>
          </button>

          <!-- Botón de edición -->
          <a
            style="margin-block: 1px; margin-left: 5px;"
            *ngIf="ver_boton_editar"
            class="btn btn-sm btn-success mr-2"
            [routerLink]="[
              id_nombre !== '' ? row[id_nombre] : row['id']
            ]"
          >
            <fa-icon [icon]="editaIcon"></fa-icon>
            {{ ruta_edita }}
          </a>

          <!-- Botón de visualizado de archivos -->
          <!-- <a
          style="margin-block: 1px; margin-left: 5px;"
            type="button"
            *ngIf="ver_boton_descargar && row['archivo']"
            target="_blank"
            [routerLink]="'/' + nombre_tabla + '/' + row[id_nombre] + '/visor'"
            class="btn btn-sm btn-success mr-2"
          >
            <fa-icon [icon]="downloadIcon"></fa-icon>
          </a>

          <button
          style="margin-block: 1px; margin-left: 5px;"
            *ngIf="ver_boton_subir"
            [disabled]="desactiva_acciones"
            (click)="
              abrir_subir_archivo(row[id_nombre])
            "
            class="btn btn-sm btn-secondary text-white mr-2"
          >
            <fa-icon [icon]="uploadIcon"></fa-icon>
          </button> -->

          <!-- Boton de borrado -->
          <button
            *ngIf="ver_boton_borrar"
            style="margin-block: 1px; margin-left: 5px;"
            [disabled]="desactiva_acciones"
            class="btn btn-sm btn-danger mr-2"
            (click)="id_nombre !== '' ? pulsa_borrar(row[id_nombre]) : pulsa_borrar(row['id'])"
          >
            <fa-icon [icon]="eliminaIcon"></fa-icon>
          </button>
        </td>
        <!-- The Modal -->
        <ng-template #formConfirmaBorrado let-modal>

        </ng-template>
      </tr>
    </tbody>
  </table>
</div>
<h3 class="text-center mt-3" *ngIf="data.length <= 0">
  No hay registros guardados
</h3>

<!-- Modal editar observaciones -->
<ng-template #formObservaciones let-modalObs>
  <div *ngIf="ver_editar_observaciones" class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Añadir/Editar Observaciones
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalObs.dismiss('')"
    ></button>
  </div>
  <div *ngIf="ver_editar_observaciones" class="modal-body">
    <app-text-editor
      [control]="observacionesEdit"
      [label]="'Observaciones'"
      [height]="300"
    />
  </div>
  <div *ngIf="ver_editar_observaciones" class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
    >
      Guardar
    </button>
    <button
      type="button"
      class="btn btn-outline-danger"
      (click)="modalObs.close('')"
    >
      Cancelar
    </button>
  </div>
</ng-template>

<!-- Modal ver observaciones -->
<ng-template #formShowObservaciones let-modalShowObs>
  <div *ngIf="ver_editar_observaciones" class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Añadir/Editar Observaciones
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalShowObs.dismiss('')"
    ></button>
  </div>
  <div *ngIf="ver_editar_observaciones" class="modal-body">
    <div [innerHTML]="this.observacionesContent"></div>
  </div>
  <div *ngIf="ver_editar_observaciones" class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-danger"
      (click)="modalShowObs.close('')"
    >
      Cancelar
    </button>
  </div>
</ng-template>

<!-- Modal editar observaciones Usuario -->
<ng-template #formObservacionesUsuario let-modalObs>
  <div *ngIf="ver_editar_observaciones" class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Añadir/Editar Observaciones
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modalObs.dismiss('')"
    ></button>
  </div>
  <div *ngIf="ver_editar_observaciones" class="modal-body">
    <app-tiny-editor
      [control]="observacionesEditUsuario"
      [label]="'Observaciones'"
      [height]="300"
    />-
  </div>
  <div *ngIf="ver_editar_observaciones" class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
    >
      Guardar
    </button>
    <button
      type="button"
      class="btn btn-outline-danger"
      (click)="modalObs.close('')"
    >
      Cancelar
    </button>
  </div>
</ng-template>
