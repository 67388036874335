import { Component, Input } from '@angular/core';
import { IconDefinition, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { globalState } from 'src/global';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss']
})
export class HeaderTopComponent {
  @Input() horario1!: string;
  @Input() horario2!: string;
  @Input() telefono!: string;
  @Input() telefono_whatsapp!: string;
  icono_telefono: IconDefinition = faPhone;
  icono_whatsapp: IconDefinition = faWhatsapp;
  idioma_seleccionado: String = globalState.idioma_usuario;
  idiomas_disponibles: String[] = [];
  idioma_seleccionables: string[] = ["es-ES", "en-EN", "fr-FR"];
  idiomas_banderas: String[] = ["../../../assets/es_640.png", "../../../assets/en_640.png", "../../../assets/fr_640.png", ];
  idioma_label: String = "";
  img_src = "";
  selector_idioma_activo: Boolean = false;
  

  ngOnInit() {
    if (this.idioma_seleccionado === "en-EN") {
      this.idiomas_disponibles = ["Spanish", "English", "French"];
      this.img_src = "../../../assets/en_640.png";
      this.idioma_label = "English"
    } else if (this.idioma_seleccionado === "fr-FR") {
      this.idiomas_disponibles = ["Espagnol", "Anglais", "Français"];
      this.img_src = "../../../assets/fr_640.png";
      this.idioma_label = "Français"
    } else {
      this.idiomas_disponibles = ["Español", "Inglés", "Frances"];
      this.img_src = "../../../assets/es_640.png";
      this.idioma_label = "Español"
    }
  }

  selecciona_idioma(idioma: string) {
    localStorage.setItem("session_idioma_diego_reinaldos", idioma);
    window.location.reload();
  }


  cambia_idioma_clicked() {
    this.selector_idioma_activo = !this.selector_idioma_activo;
  }
}
